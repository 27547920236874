import React, { useState, useEffect, useRef } from "react"


import {
    Label,
    Card,
    CardBody,
    Button,
    Col,
    Form,
    Input,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardTitle,
    CardImg,
} from "reactstrap"
import classnames from "classnames"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';
import Axios from "../../helpers/axios_helper"
import * as CHelper from "../../helpers/custom_helper"
import { useHistory, Link } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/media.jpeg";
import './model.scss'
const moment = require('moment');

const Model = (props) => {
    const history = useHistory()
    const [activeTab, setactiveTab] = useState(1)
    const [courseTypes, setCourseTypes] = useState([]);
    const [courseTypeId, setCourseTypeId] = useState()
    const [updateData, setUpdateData] = useState(false)
    const [imageSrc, setImageSrc] = useState(mediaImage);
    const [files, setFiles] = useState([]);

    const [overviewState, setOverviewState] = useState(EditorState.createEmpty());
    const [overviewContent, setOverviewContent] = useState();
    const [outlineState, setOutlineState] = useState(EditorState.createEmpty());
    const [outlineContent, setOutlineContent] = useState();

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab)
            }
        }
    }

    const handleOverviewChange = (newEditorState) => {
        setOverviewState(newEditorState);
        setOverviewContent(stateToHTML(newEditorState.getCurrentContent()));
    };

    const handleOutlineChange = (newEditorState) => {
        setOutlineState(newEditorState);
        setOutlineContent(stateToHTML(newEditorState.getCurrentContent()));
    };

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        if (errors.length > 0) {
            errors.forEach(element => {
                toast.error(`${CHelper.separateCamelCaseString(element)} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        // else if (props.id && props.id > 0 && imageSrc == mediaImage) {
        //     toast.error(`Feature image is required !`, {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 3000,
        //     });
        // }
        // else if (!props.id && files.length == 0) {
        //     toast.error(`Feature image is required !`, {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 3000,
        //     });
        // }
        else {
            if (props.id && props.id == updateData.id) {
                const jsonData = {
                    courseTypeId: courseTypeId,
                    courseName: values.courseName,
                    courseCode: values.courseCode,
                    courseOverview: overviewContent,
                    courseOutline: outlineContent,
                    courseDurationInHours: values.courseDurationInHours,
                    courseFee: values.courseFee>0?values.courseFee:0,
                    courseYoutubeLink: values.courseYoutubeLink,
                    courseStatus: 1
                }

                await Axios.patch(`/course/id/${updateData.id}`, jsonData)
                    .then(async (response) => {
                        if (response.data.status === 200) {

                            const formData = new FormData();
                            for (let i = 0; i < files.length; i++) {
                                formData.append('file', files[i]);
                            }
                            if (files.length > 0 && updateData.files) {
                                const removeFilesName = [];
                                for (let i = 0; i < updateData.files.length; i++) {
                                    removeFilesName.push(updateData.files[i].name);
                                }
                                formData.append(`remove`, JSON.stringify(removeFilesName));
                            }
                            await Axios.post(`/file/upload/course&${updateData.id}`, formData, {
                            })
                                .then(response => {
                                    toast.success('Course updated successfully!', {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                    props.handleCallback(response.data)
                                })
                                .catch(function (error) {
                                    toast.error("File Uploaded Failed !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                });
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }
            else {
                const jsonData = {
                    courseTypeId: courseTypeId,
                    courseName: values.courseName,
                    courseCode: values.courseCode,
                    courseOverview: overviewContent,
                    courseOutline: outlineContent,
                    courseDurationInHours: values.courseDurationInHours,
                    courseFee: values.courseFee>0?values.courseFee:0,
                    courseYoutubeLink: values.courseYoutubeLink,
                    courseStatus: 1
                }

                await Axios.post("/course", jsonData)
                    .then(async (response) => {
                        if (response.data.status === 201) {

                            const formData = new FormData();
                            for (let i = 0; i < files.length; i++) {
                                formData.append('file', files[i]);
                            }

                            await Axios.post(`/file/upload/course&${response.data.id}`, formData, {
                            })
                                .then(res => {
                                    toast.success('Course created successfully!', {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                    setTimeout(() => {
                                        history.push("/course-list");
                                    }, 1000);
                                })
                                .catch(function (error) {
                                    toast.error("File Uploaded Failed !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                });
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }

        }
        
        window.hLoader();
    }

    const fileChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setFiles([...event.target.files]);
        CHelper.convertToWebP(documentFile, setImageSrc);
    }


    useEffect(async () => {
        if (props.id && props.id > 0) {
            await Axios.get(`/course/details/id/${props.id}`)
                .then((response) => {
                    if (response.data.status === 200) {
                        setCourseTypeId(response.data.data.courseTypeId);
                        setUpdateData(response.data.data);

                        setOverviewContent(response.data.data.courseOverview)
                        const courseOverview = EditorState.createWithContent(stateFromHTML(response.data.data.courseOverview));
                        setOverviewState(courseOverview)

                        setOutlineContent(response.data.data.courseOutline);
                        const courseOutline = EditorState.createWithContent(stateFromHTML(response.data.data.courseOutline));
                        setOutlineState(courseOutline)

                        if(response.data.data.files.length>0){
                            setImageSrc(CHelper.getsimplifyLink(response.data.data.files[0].link))
                        }
                        
                    }
                })
        }
        await Axios.get("/courseType/dropdown")
            .then((response) => {
                if (response.data.status === 200) {
                    setCourseTypes(response.data.data);
                }
                else {
                    setCourseTypes([])
                }
            });

        return () => {
            // Cancel any outstanding requests or subscriptions here
        };
    }, [props.id]);


    return (
        <Row>
            {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
                <Col xl="12">
                    <Card>
                        <CardBody>
                            <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                <Row>
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                {/* <h4 className="card-title">Jquery Steps Wizard</h4>
                                                    <p className="card-title-desc">A powerful jQuery wizard plugin that supports
                                                                        accessibility and HTML5</p> */}
                                                <div className="form-wizard-wrapper wizard clearfix">
                                                    <div className="steps courseCustomSteps clearfix">
                                                        <ul>
                                                            <NavItem
                                                                className={classnames({ current: activeTab === 1 })}>
                                                                <NavLink
                                                                    className={classnames({ current: activeTab === 1 })}
                                                                    onClick={() => {
                                                                        // setactiveTab(1)
                                                                    }}
                                                                >
                                                                    <span className="number">1.</span>{" "}
                                                                    Basic Details
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 2 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 2 })}
                                                                    onClick={() => {
                                                                        // setactiveTab(2)
                                                                    }}
                                                                >
                                                                    <span className="number">2.</span>{" "}
                                                                    Overview
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 3 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 3 })}
                                                                    onClick={() => {
                                                                        // setactiveTab(3)
                                                                    }}
                                                                >
                                                                    <span className="number">3.</span>
                                                                    Outline
                                                                </NavLink>
                                                            </NavItem>
                                                        </ul>
                                                    </div>
                                                    <div className="content clearfix">
                                                        <TabContent
                                                            activeTab={activeTab}
                                                            className="body"
                                                        >
                                                            <TabPane tabId={1}>

                                                                <Row>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="courseTypeId">Course Type</Label>
                                                                            <Select
                                                                                options={courseTypes}
                                                                                value={courseTypes ? courseTypes.filter(x => x.value == courseTypeId)[0] : null}
                                                                                onChange={(e) => { setCourseTypeId(e.value); }}
                                                                                name="courseTypeId"
                                                                                id="courseTypeId"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="courseName">Course name</Label>
                                                                            <AvField
                                                                                name="courseName"
                                                                                defaultValue={updateData.courseName}
                                                                                placeholder="Name"
                                                                                type="text"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('courseName')}`}
                                                                                className="form-control"
                                                                                validate={{ required: { value: true } }}
                                                                                id="courseName"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="courseCode">Course Code</Label>
                                                                            <AvField
                                                                                name="courseCode"
                                                                                defaultValue={updateData.courseCode}
                                                                                placeholder="Code"
                                                                                type="text"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('courseCode')}`}
                                                                                className="form-control"
                                                                                validate={{ required: { value: true } }}
                                                                                id="courseCode"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="courseFee">Course Fee</Label>
                                                                            <AvField
                                                                                name="courseFee"
                                                                                defaultValue={updateData.courseFee}
                                                                                placeholder="0"
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="courseFee"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="courseDurationInHours">Course Duration</Label>
                                                                            {/* <Flatpickr
                                                                                className="form-control d-block"
                                                                                placeholder="dd M,yyyy"
                                                                                options={{
                                                                                    mode: "range",
                                                                                    dateFormat: "d-m-y",
                                                                                }}
                                                                                name="courseDurationInHours"
                                                                                id="courseDurationInHours"
                                                                            /> */}
                                                                            <AvField
                                                                                name="courseDurationInHours"
                                                                                defaultValue={updateData.courseDurationInHours}
                                                                                placeholder="Hours"
                                                                                type="number"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('courseDurationInHours')}`}
                                                                                className="form-control"
                                                                                validate={{ required: { value: true } }}
                                                                                id="courseDurationInHours"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="courseYoutubeLink">Course Youtube Link</Label>
                                                                            <AvField
                                                                                name="courseYoutubeLink"
                                                                                defaultValue={updateData.courseYoutubeLink}
                                                                                placeholder="YoutubeLink"
                                                                                type="text"
                                                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('courseYoutubeLink')}`}
                                                                                className="form-control"
                                                                                validate={{ required: { value: true } }}
                                                                                id="courseYoutubeLink"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    {/* <Col md="6">
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="validationCustom03">Active</Label>
                                                                        <div className="square-switch">
                                                                            <input
                                                                            type="checkbox"
                                                                            id="isActive"
                                                                            name="isActive"
                                                                            switch="none"
                                                                            checked={active}
                                                                            onChange={() => {
                                                                                setActive(!active);
                                                                            }}
                                                                            />
                                                                            <label
                                                                            htmlFor="isActive"
                                                                            data-on-label="On"
                                                                            data-off-label="Off"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    </Col> */}
                                                                </Row>
                                                            </TabPane>
                                                            <TabPane tabId={2}>
                                                                <Row>
                                                                    <Col md={7}>
                                                                        <Col md={12}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="overviewState">Overview</Label>
                                                                                <Editor
                                                                                    editorState={overviewState}
                                                                                    onEditorStateChange={handleOverviewChange}
                                                                                    toolbarClassName="toolbarClassName"
                                                                                    wrapperClassName="wrapperClassName"
                                                                                    editorClassName="editorClassName"
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Col>
                                                                    <Col md={5}>
                                                                        <Col md={12}>
                                                                            <div className="mb-3" style={{ textAlign: "center" }}>
                                                                                <CardImg className="img-fluid" src={imageSrc} alt="" style={{ maxHeight: "300px", width: "auto" }} />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={12}>
                                                                            <div className="mb-3 justify-content-center d-flex flex-row">
                                                                                <label type="button" className="btn btn btn-info col-md-5" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Change Image<input hidden onChange={fileChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" /></label>
                                                                                <label hidden style={{ textAlign: "center" }} type="button" onClick={() => CHelper.downloadImage(imageSrc)} className="btn btn btn-primary col-md-5"><i className="fa fa-download"></i> Download Image</label>
                                                                            </div>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                            <TabPane tabId={3}>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="outlineState">Outline</Label>
                                                                            <Editor
                                                                                editorState={outlineState}
                                                                                onEditorStateChange={handleOutlineChange}
                                                                                toolbarClassName="toolbarClassName"
                                                                                wrapperClassName="wrapperClassName"
                                                                                editorClassName="editorClassName"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                            </TabPane>
                                                        </TabContent>
                                                    </div>
                                                    <div className="actions clearfix">
                                                        {activeTab != 1 && 
                                                            <Button
                                                            style={{float:"left"}}
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => {
                                                                toggleTab(activeTab - 1)
                                                            }}
                                                            >Previous</Button>
                                                        }
                                                        {activeTab === 3 ?
                                                            <Button color="primary" type="submit">
                                                                Submit
                                                            </Button>
                                                            :
                                                            <Link
                                                                to="#"
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    toggleTab(activeTab + 1)
                                                                }}
                                                            >Next</Link>
                                                        }
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            }
        </Row>
    )
}

export default Model
