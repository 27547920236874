import React, { useState, useEffect, useRef } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Label,
  CardImg,
  Table
} from "reactstrap"
import { Delete,Edit } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import Axios from "../../helpers/axios_helper"
import * as CHelper from "../../helpers/custom_helper"
import * as SHelper from "../../helpers/session_helper"
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/media.jpeg";
const moment = require('moment');

const Model = (props) => {
  const history = useHistory()
  const [modules, setModules] = useState([]);
  const [updateData, setUpdateData] = useState(false)
  const [files, setFiles] = useState([]);

  const [moduleId, setModuleId] = useState(-1);
  const [moduleIdx, setModuleIdx] = useState(-1);
  const [moduleName, setModuleName] = useState('');
  const [moduleSequence, setModuleSequence] = useState('');
  const [moduleDescription, setModuleDescription] = useState('');

  const handleSubmit = async (event, errors, values) => {
    if (!props.courseId) {
        toast.error(`Course is required !`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
    }
    else {
      var updateData=modules;
      updateData.forEach(element => {
        element.courseId=props.courseId;
        if(element.courseModuleIsActive){
          element.courseModuleIsActive=element.courseModuleIsActive==1?true:false;
        }
      });

      const jsonData = {
        modules:updateData
      }

      await Axios.post("/courseModule/bulk", jsonData)
        .then(async (response) => {
          if (response.data.status === 200) {
            toast.success('Course Modules Created/Updated successfully!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
                
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        })
        .catch((e) => {
          toast.error(e, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        })

    }

  }

  const addModule = () =>{
    if(moduleIdx>-1){
        modules[moduleIdx].id=moduleId;
        modules[moduleIdx].courseModuleName=moduleName;
        modules[moduleIdx].courseModuleSequence=moduleSequence;
        modules[moduleIdx].courseModuleDescription=moduleDescription;
    }
    else{
      modules.push({
            courseModuleName:moduleName,
            courseModuleSequence:moduleSequence,
            courseModuleDescription:moduleDescription
        });
    }
    setModules(modules);
    cancelModuleEdit();
  }

  const cancelModuleEdit = () =>{
    setModuleIdx(-1);
    setModuleId(-1);
    setModuleName('');
    setModuleSequence('')
    setModuleDescription('')
  }

  const editModule = (id,idx) =>{
    setModuleId(id);
    setModuleIdx(idx);
    setModuleName(modules[idx].courseModuleName);
    setModuleSequence(modules[idx].courseModuleSequence);
    setModuleDescription(modules[idx].courseModuleDescription);
  }
  const deleteModule = (id,idx) =>{

  }

  useEffect(async ()=>{
    if(props.courseId){
        await Axios.get("/courseModule/list")
          .then((response) => {
              if (response.data.recordsTotal) {
                setModules(response.data.data);
              }
              else {
                setModules([])
              }
          });
    }
  },[props.courseId])
  return (
    <Row>
      {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
        <Col xl="12">
          <Card>
            <CardTitle style={{ fontSize: "16px", textAlign: "center", textDecoration: "underline" }}>Modules</CardTitle>
            <CardBody style={{ paddingTop: "0.5rem" }}>
              <AvForm className="needs-validation" onSubmit={handleSubmit}>
              {SHelper.userHasEditPermission() &&
              <>
                <Col md="12">
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <Label>Name</Label>
                        <AvField
                          name="courseModuleName"
                          value={moduleName}
                          onChange={(e)=>{setModuleName(e.target.value);}}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label>Sequence</Label>
                        <AvField
                          name="courseTypeSequence"
                          value={moduleSequence}
                          onChange={(e)=>{setModuleSequence(e.target.value);}}
                          type="number"
                          className="form-control"
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="mb-3">
                        <Label>Description</Label>
                        <AvField
                          name="courseTypeDescription"
                          value={moduleDescription}
                          onChange={(e)=>{setModuleDescription(e.target.value);}}
                          type="textarea"
                          className="form-control"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <div className="actions clearfix">
                    <Button
                        style={{ float: "right", marginLeft: "10px" }}
                        type="button"
                        color="primary"
                        onClick={addModule}
                    >{ moduleIdx>-1 ? "Update":"Add"}</Button>
                    { moduleIdx >-1 && 
                    <Button
                        style={{ float: "right" }}
                        type="button"
                        color="danger"
                        onClick={cancelModuleEdit}
                    >Cancel</Button>}
                </div>
                </>}
                <CardTitle typeof="h4" >Modules List</CardTitle>
                  <Col md={12}>
                      <div className="table-responsive">
                          <Table className="table mb-0">
                              <thead>
                                  <tr>
                                      <th>#</th>
                                      <th>Name</th>
                                      <th>Description</th>
                                      <th>Sequence</th>
                                      <th>Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                              {modules.map((item,i)=>{
                                      return (
                                          <tr key={i}>
                                              <th scope="row">{i+1}</th>
                                              <td>{item.courseModuleName}</td>
                                              <td>{item.courseModuleDescription.length>20?`${item.courseModuleDescription.substring(0, 20)} ...`:item.courseModuleDescription}</td>
                                              <td>{item.courseModuleSequence}</td>
                                              <td>
                                                  <Box sx={{ display: moduleIdx===i?'none':'flex' }}>
                                                      {SHelper.userHasEditPermission() && <>
                                                      <Tooltip arrow placement="left" title="Edit">
                                                      <IconButton onClick={() => {editModule(item.id,i)}}>
                                                          <Edit />
                                                      </IconButton>
                                                      </Tooltip>
                                                      <Tooltip arrow placement="left" title="Delete">
                                                      <IconButton style={{color:"red"}} onClick={() => {deleteModule(item.id,i)}}>
                                                          <Delete />
                                                      </IconButton>
                                                      </Tooltip>
                                                      </>}
                                                  </Box>
                                              </td>
                                          </tr>
                                      )
                                  })}
                              </tbody>
                          </Table>
                      </div>
                  </Col>
                  {modules.length>0 && 
                  <div className="actions clearfix">
                    <Button
                        style={{ float: "right", marginLeft: "10px" }}
                        type="submit"
                        color="primary"
                    >Update</Button>
                </div>}
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      }
    </Row>
  )
}

export default Model
