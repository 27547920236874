module.exports = {
  applicationInfo:{
    appName: "Sus-Plan",
    devOrgName: "Bangladesh Institute of Management (BIM)",
    devOrgLink: "http://www.bim.gov.bd/" 
  },
  applicationSettings:{
    developmentBaseURL :  "https://braintechsolution.com:3335/api/",//"http://localhost:3334/api/",//
    productionBaseURL : "https://braintechsolution.com:3331/api/",//"https://api.sustainability.com.bd/api",//
    baseURL_ : "http://localhost:3334/api/",
  },
  zoomSettings:{
    baseURL : "http://localhost:3001/",
  }
}
