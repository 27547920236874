import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, InputGroup } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Axios from "../../helpers/axios_helper";
import { useHistory } from "react-router-dom"
import * as CHelper from "../../helpers/custom_helper";
import * as SHelper from "../../helpers/session_helper";

const LineChart = () => {
    const history = useHistory()
    const [userDetails, setUserDetails] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [classes, setClasses] = useState([])

    useEffect(() => {
        // const user=CHelper.isUserRole(CHelper.userRole.Administrator);                    
        setUserDetails(true);
    },[])

    useEffect(() => {
        setClasses([])
        const date = SHelper.getMySqlDateFormat(selectedDate);
        Axios.get(`/dashboard/class/${date}`)
            .then(function (response) {
                if (response && response.data) {
                    setClasses(response.data);
                }
            })
            .catch(function (error) {
                // navigate('/');
            });
    }, [selectedDate])

    const updateCourseStartTime = (course) =>{
        Axios.patch(`/courseClass/updateStartTime/${course.id}`)
        .then(function (response) {
            if (response && response.data.status==200) {
                SHelper.startMeeting(course.courseZoomDetails);
            }
        })
        .catch(function (error) {
            // navigate('/');
        });
    }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="float-end">
                        <div className="input-group input-group">
                            <InputGroup>
                                <Flatpickr
                                    value={selectedDate}
                                    className="form-control d-block"
                                    placeholder="Pick a date"
                                    options={{
                                        altInput: true,
                                        dateFormat: "d-m-y",
                                    }}
                                    onChange={(date) => setSelectedDate(date[0])}
                                />
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                        disabled
                                    >
                                        <i
                                            className="fa fa-calendar"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </InputGroup>
                        </div>
                    </div>
                    <CardTitle className="h4 mb-4">Class Details</CardTitle>

                    {/* <div className="align-items-start d-flex">
                        <div className="flex-1">
                            <p className="mb-2">Total Social source</p>
                            <h4>$ 8,974</h4>
                            <p className="mb-0"><span className="badge badge-soft-success me-2"> 0.6% <i
                                className="mdi mdi-arrow-up"></i> </span> From previous period</p>
                        </div>
                    </div> */}

                    {classes && classes.map((item, i) => {
                        return (
                            <div key={i} className="mt-3 social-source">
                                <div className="d-flex align-items-center social-source-list">
                                    <div className="avatar-xs me-4">
                                        <span className="avatar-title rounded-circle">
                                            <i className="mdi mdi-account-multiple-outline"></i>
                                        </span>
                                    </div>
                                    <div className="flex-1">
                                        <h5 className="mb-0">{item.courseName} <span style={{fontSize:"12px"}}>(Class : {item.courseClassName})</span></h5>
                                        <p className="mb-1 pt-1">Start Datetime : {item.courseStartDatetime}</p>
                                    </div>
                                    <div className="ms-auto">
                                        {/* 2.06 % <i className="mdi mdi-arrow-up text-success ms-1"></i> */}
                                        {((new Date()> new Date(item.courseStartDatetime)) && (new Date()< new Date(item.courseEndDatetime))) ? 
                                        <>
                                        {(SHelper.isUserRole(SHelper.userRole.Instructor) || SHelper.isUserRole(SHelper.userRole.Administrator))&&
                                        <button type="button" className="btn btn-primary waves-effect" onClick={()=>SHelper.startMeeting(item.courseZoomDetails)}>
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                            Start
                                        </button>}
                                        {(SHelper.isUserRole(SHelper.userRole.Academic)|| SHelper.isUserRole(SHelper.userRole.Participant)) &&
                                        <button type="button" className="btn btn-primary waves-effect" onClick={()=>SHelper.joinMeeting(item.courseZoomDetails, history)}>
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                            Join
                                        </button>}
                                        </>
                                        :
                                        <>
                                        {(new Date()> new Date(item.courseEndDatetime)) ?
                                        <button type="button" className="btn btn-light waves-effect" disabled={true}>
                                            Meeting Ended
                                        </button>
                                        :
                                        <>
                                        {(SHelper.isUserRole(SHelper.userRole.Instructor) || SHelper.isUserRole(SHelper.userRole.Administrator))&&
                                        <button type="button" className="btn btn-primary waves-effect" onClick={()=>updateCourseStartTime(item)}>
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                            Start Now
                                        </button>}
                                        {(SHelper.isUserRole(SHelper.userRole.Academic)|| SHelper.isUserRole(SHelper.userRole.Participant)) &&
                                        <button type="button" className="btn btn-light waves-effect" disabled={true}>
                                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                                            Not Start Yet
                                        </button>}
                                        </>  
                                        }
                                        </>}
                                        {(new Date()> new Date(item.courseEndDatetime)) &&
                                            <button type="button" className="btn btn-primary waves-effect" style={{marginLeft:"10px"}} onClick={() => {alert("Paid Version Needed")}}>
                                                Download Class Record
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}


                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default LineChart