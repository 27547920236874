import React, { useState, useEffect, useRef } from "react"

import {
    Label,
    Card,
    CardBody,
    Button,
    Col,
    Form,
    Input,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardTitle,
    CardImg,
} from "reactstrap"

import classnames from "classnames"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"
// Form Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';

import * as CHelper from "../../../helpers/custom_helper"

import Axios from "../../../helpers/axios_helper"
import { useHistory, Link } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../../assets/images/media.jpeg";

const Model = (props) => {
    const history = useHistory()
    const [active, setActive] = useState(true);
    const [menuHeads, setMenuHeads] = useState([]);
    const [menuHeadId, setMenuHeadId] = useState(0)
    const [updateData, setUpdateData] = useState(false)
    const [imageSrc, setImageSrc] = useState(mediaImage);
    const [files, setFiles] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorContent, setEditorContent] = useState();

    const [activeTab, setactiveTab] = useState(1)

    useEffect(async () => {
        window.sLoader();
        if (props.id && props.id > 0) {
            await Axios.get(`/page/id/${props.id}`)
                .then((response) => {
                    if (response.data.status === 200) {
                        setUpdateData(response.data.data);
                        setMenuHeadId(response.data.data.menuHeadId)

                        setEditorContent(response.data.data.pageContent)
                        const eventOverview = EditorState.createWithContent(stateFromHTML(response.data.data.pageContent));
                        setEditorState(eventOverview)

                        if(response.data.data.files.length>0){
                            setImageSrc(CHelper.getsimplifyLink(response.data.data.files[0].link))
                        }

                        // if(response.data.data.pageFeatureImage)
                    }
                })
        }
        await Axios.get("/menuHead/dropdown")
            .then((response) => {
                if (response.data.status === 200) {
                    setMenuHeads(response.data.data);
                }
                else {
                    setMenuHeads([])
                }
            });
        window.hLoader();
    }, [props.id]);

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab)
            }
        }
    }

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        if (errors.length > 0) {
            errors.forEach(element => {
                toast.error(`${element} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        else if (menuHeadId == 0) {
            toast.error(`Menu head is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {
            if (props.id && props.id == updateData.id) {
                const jsonData = {
                    menuHeadId: menuHeadId,
                    pageName: values.pageName,
                    pageTitle: values.pageTitle,
                    pageBreadcrumb: values.pageBreadcrumb,
                    pageIsActive: active,
                    pageContent: editorContent,
                    pageStatus: 1
                }
    
                await Axios.patch(`/page/id/${updateData.id}`, jsonData)
                    .then(async (response) => {
                        if (response.data.status === 200) {
    
                            const formData = new FormData();
                            for (let i = 0; i < files.length; i++) {
                                formData.append('file', files[i]);
                            }
                            
                            if (files.length > 0 && updateData.files) {
                                const removeFilesName = [];
                                for (let i = 0; i < updateData.files.length; i++) {
                                    removeFilesName.push(updateData.files[i].name);
                                }
                                formData.append(`remove`, JSON.stringify(removeFilesName));
                            }

                            await Axios.post(`/file/upload/page&${updateData.id}`, formData, {
                            })
                                .then(res => {
                                    toast.success('Page updated successfully!', {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                    props.handleCallback(response.data)
                                })
                                .catch(function (error) {
                                    toast.error("File Uploaded Failed !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                });
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }else{
                const jsonData = {
                    menuHeadId: menuHeadId,
                    pageName: values.pageName,
                    pageTitle: values.pageTitle,
                    pageBreadcrumb: values.pageBreadcrumb,
                    pageIsActive: active,
                    pageContent: editorContent,
                    pageStatus: 1
                }
    
                await Axios.post("/page", jsonData)
                    .then(async (response) => {
                        if (response.data.status === 201) {
    
                            const formData = new FormData();
                            for (let i = 0; i < files.length; i++) {
                                formData.append('file', files[i]);
                            }
    
                            await Axios.post(`/file/upload/page&${response.data.id}`, formData, {
                            })
                                .then(res => {
                                    toast.success('Page created successfully!', {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                    setTimeout(() => {
                                        history.push("/page-list");
                                    }, 1000);
                                })
                                .catch(function (error) {
                                    toast.error("File Uploaded Failed !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                });
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }
            
        }
        
        window.hLoader();
    }

    const fileChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setFiles([...event.target.files]);
        CHelper.convertToWebP(documentFile, setImageSrc);
    }

    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
        setEditorContent(stateToHTML(newEditorState.getCurrentContent()));
    };

    useEffect(async () => {

    }, [props.id]);

    return (
        <Row>
            {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
                <Col xl="12">
                    <Card>
                        <CardBody>
                            <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                <Row>
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                {/* <h4 className="card-title">Jquery Steps Wizard</h4>
                                                    <p className="card-title-desc">A powerful jQuery wizard plugin that supports
                                                                        accessibility and HTML5</p> */}
                                                <div className="form-wizard-wrapper wizard clearfix">
                                                    <div className="steps clearfix">
                                                        <ul>
                                                            <NavItem
                                                                className={classnames({ current: activeTab === 1 })}>
                                                                <NavLink
                                                                    className={classnames({ current: activeTab === 1 })}
                                                                    onClick={() => {
                                                                        setactiveTab(1)
                                                                    }}
                                                                >
                                                                    <span className="number">1.</span>{" "}
                                                                    Basic Details
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 2 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 2 })}
                                                                    onClick={() => {
                                                                        setactiveTab(2)
                                                                    }}
                                                                >
                                                                    <span className="number">2.</span>{" "}
                                                                    Feature Image
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 3 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 3 })}
                                                                    onClick={() => {
                                                                        setactiveTab(3)
                                                                    }}
                                                                >
                                                                    <span className="number">3.</span>
                                                                    Content
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className={classnames({ current: activeTab === 4 })}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === 4 })}
                                                                    onClick={() => {
                                                                        setactiveTab(4)
                                                                    }}
                                                                >
                                                                    <span className="number">4.</span>
                                                                    Publish
                                                                </NavLink>
                                                            </NavItem>
                                                        </ul>
                                                    </div>
                                                    <div className="content clearfix">
                                                        <TabContent
                                                            activeTab={activeTab}
                                                            className="body"
                                                        >
                                                            <TabPane tabId={1}>

                                                                <Row>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label>Menu Head</Label>
                                                                            <Select
                                                                                options={menuHeads}
                                                                                value={menuHeads ? menuHeads.filter(x => x.value == menuHeadId)[0] : null}
                                                                                onChange={(e) => { setMenuHeadId(e.value); }}
                                                                                name="menuHeadId"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="pageName">Menu name</Label>
                                                                            <AvField
                                                                                name="pageName"
                                                                                defaultValue={updateData.pageName}
                                                                                placeholder="Name"
                                                                                type="text"
                                                                                errorMessage=" Please provide a Menu name."
                                                                                className="form-control"
                                                                                validate={{ required: { value: true } }}
                                                                                id="pageName"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="pageBreadcrumb">Breadcrumb</Label>
                                                                            <AvField
                                                                                name="pageBreadcrumb"
                                                                                defaultValue={updateData.pageBreadcrumb}
                                                                                placeholder="Breadcrumb"
                                                                                type="text"
                                                                                errorMessage=" Please provide a Breadcrumb."
                                                                                className="form-control"
                                                                                validate={{ required: { value: true } }}
                                                                                id="pageBreadcrumb"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="pageIsActive">Active</Label>
                                                                            <div className="square-switch">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id="pageIsActive"
                                                                                    name="pageIsActive"
                                                                                    switch="none"
                                                                                    checked={active}
                                                                                    onChange={() => {
                                                                                        setActive(!active);
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    htmlFor="pageIsActive"
                                                                                    data-on-label="On"
                                                                                    data-off-label="Off"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                            <TabPane tabId={2}>

                                                                <Row>
                                                                    <Col md={12}>
                                                                        <div className="mb-3" style={{ textAlign: "center" }}>
                                                                            {/* <CardTitle className="h4">Feature Image</CardTitle> */}
                                                                            <CardImg className="img-fluid" src={imageSrc} alt="" style={{ maxHeight: "300px", width: "auto" }} />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <div className="mb-3 justify-content-center d-flex flex-row">
                                                                            <label type="button" className="btn btn btn-info col-md-5" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Change Image<input hidden onChange={fileChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" multiple="multiple" /></label>
                                                                            <label hidden style={{ textAlign: "center" }} type="button" onClick={() => CHelper.downloadImage(imageSrc)} className="btn btn btn-primary col-md-5"><i className="fa fa-download"></i> Download Image</label>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                            <TabPane tabId={3}>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="pageTitle">Title</Label>
                                                                            <AvField
                                                                                name="pageTitle"
                                                                                defaultValue={updateData.pageTitle}
                                                                                placeholder="Title"
                                                                                type="text"
                                                                                errorMessage=" Please provide a Title."
                                                                                className="form-control"
                                                                                validate={{ required: { value: true } }}
                                                                                id="pageTitle"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="validationCustom05">Description</Label>
                                                                            <Editor
                                                                                editorState={editorState}
                                                                                onEditorStateChange={handleEditorChange}
                                                                                toolbarClassName="toolbarClassName"
                                                                                wrapperClassName="wrapperClassName"
                                                                                editorClassName="editorClassName"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                            </TabPane>
                                                            <TabPane tabId={4}>
                                                                <div className="row justify-content-center">
                                                                    <Col lg="6">
                                                                        <div className="text-center">
                                                                            <div className="mb-4">
                                                                                <i className="mdi mdi-check-circle-outline text-success display-4" />
                                                                            </div>
                                                                            <div>
                                                                                <h5>Confirm Detail</h5>
                                                                                <p className="text-muted">
                                                                                    If several languages coalesce, the grammar of
                                                                                    the resulting
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </div>
                                                                {/* <Col md={12}>
                                                                    <div className="mb-3">
                                                                        <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
                                                                    </div>
                                                                </Col> */}
                                                            </TabPane>
                                                        </TabContent>
                                                    </div>
                                                    <div className="actions clearfix">
                                                        <ul>
                                                            <li
                                                                className={
                                                                    activeTab === 1 ? "previous disabled" : "previous"
                                                                }
                                                            >
                                                                <Link
                                                                    to="#"
                                                                    className="btn btn-primary"
                                                                    onClick={() => {
                                                                        toggleTab(activeTab - 1)
                                                                    }}
                                                                >
                                                                    Previous
                                                                </Link>
                                                            </li>
                                                            {activeTab === 4 ?
                                                                <li>
                                                                    <Button color="primary" type="submit">
                                                                        Submit
                                                                    </Button>
                                                                </li>
                                                                :
                                                                <li
                                                                    className={activeTab === 4 ? "next disabled" : "next"}
                                                                >
                                                                    <Link
                                                                        to="#"
                                                                        className="btn btn-primary"
                                                                        onClick={() => {
                                                                            toggleTab(activeTab + 1)
                                                                        }}
                                                                    >
                                                                        Next
                                                                    </Link>
                                                                </li>
                                                            }

                                                        </ul>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            }
        </Row>
    )
}

export default Model
