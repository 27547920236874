import React, { useState, useEffect, useRef } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label,
    CardImg
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"


import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';
import Select from "react-select"

import Axios from "../../../helpers/axios_helper"
import * as CHelper from "../../../helpers/custom_helper"
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../../assets/images/media.jpeg";
const moment = require('moment');

const Model = (props) => {
    const history = useHistory();
    const [updateData, setUpdateData] = useState(false);
    const [imageSrc, setImageSrc] = useState(mediaImage);
    const [overviewState, setOverviewState] = useState(EditorState.createEmpty());
    const [overviewContent, setOverviewContent] = useState();
    const [files, setFiles] = useState([]);

    const handleSubmit = async (event, errors, values) => {
        if (errors.length > 0) {
            errors.forEach(element => {
                toast.error(`${CHelper.separateCamelCaseString(element)} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        else if (props.id && props.id > 0 && imageSrc == mediaImage) {
            toast.error(`Feature image is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else if (!props.id && files.length == 0) {
            toast.error(`Feature image is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {
            if (props.id && props.id == updateData.id) {
                const jsonData = {
                    newsAndBlogName: values.newsAndBlogName,
                    newsAndBlogTags: values.newsAndBlogTags,
                    newsAndBlogIsItNews:true,
                    newsAndBlogDetails: overviewContent
                }

                await Axios.patch(`/newsAndBlog/id/${updateData.id}`, jsonData)
                    .then(async (response) => {
                        if (response.data.status === 200) {

                            const formData = new FormData();
                            for (let i = 0; i < files.length; i++) {
                                formData.append('file', files[i]);
                            }
                            if (files.length > 0) {
                                const removeFilesName = [];
                                for (let i = 0; i < updateData.files.length; i++) {
                                    removeFilesName.push(updateData.files[i].name);
                                }
                                formData.append(`remove`, JSON.stringify(removeFilesName));
                            }
                            await Axios.post(`/file/upload/newsAndBlog&${updateData.id}`, formData, {
                            })
                                .then(response => {
                                    toast.success('Course Type updated successfully!', {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                    props.handleCallback(response.data)
                                })
                                .catch(function (error) {
                                    toast.error("File Uploaded Failed !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                });
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }
            else {
                const jsonData = {
                    newsAndBlogName: values.newsAndBlogName,
                    newsAndBlogTags: values.newsAndBlogTags,
                    newsAndBlogIsItNews:true,
                    newsAndBlogDetails: overviewContent
                }

                await Axios.post("/newsAndBlog", jsonData)
                    .then(async (response) => {
                        if (response.data.status === 201) {

                            const formData = new FormData();
                            for (let i = 0; i < files.length; i++) {
                                formData.append('file', files[i]);
                            }

                            await Axios.post(`/file/upload/newsAndBlog&${response.data.id}`, formData, {
                            })
                                .then(res => {
                                    toast.success('News created successfully!', {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                    setTimeout(() => {
                                        history.push("/news-list");
                                    }, 1000);
                                })
                                .catch(function (error) {
                                    toast.error("File Uploaded Failed !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                });
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }

        }

    }

    const fileChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setFiles([...event.target.files]);
        CHelper.convertToWebP(documentFile, setImageSrc);
    }


    const handleOverviewChange = (newEditorState) => {
        setOverviewState(newEditorState);
        setOverviewContent(stateToHTML(newEditorState.getCurrentContent()));
    };

    useEffect(async () => {
        if (props.id && props.id > 0) {
            await Axios.get(`/newsAndBlog/details/id/${props.id}`)
                .then((response) => {
                    if (response.data.status === 200) {
                        setUpdateData(response.data.data);
                        setOverviewContent(response.data.data.newsAndBlogDetails);
                        const newsAndBlogDetails = EditorState.createWithContent(stateFromHTML(response.data.data.newsAndBlogDetails));
                        setOverviewState(newsAndBlogDetails)
                        if (response.data.data.files.length > 0) {
                            setImageSrc(CHelper.getsimplifyLink(response.data.data.files[0].link))
                        }
                    }
                })
        }
    }, [props.id]);


    return (
        <Row>
            {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
                <Col xl="12">
                    <Card>
                        <CardBody>
                            <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label htmlFor="newsAndBlogName">Name / Title</Label>
                                            <AvField
                                                name="newsAndBlogName"
                                                defaultValue={updateData.newsAndBlogName}
                                                // placeholder="News Name"
                                                type="text"
                                                className="form-control"
                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('name')}`}
                                                validate={{ required: { value: true } }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3" style={{ textAlign: "center" }}>
                                                    {/* <CardTitle className="h4">Feature Image</CardTitle> */}
                                                    <CardImg className="img-fluid" src={imageSrc} alt="" style={{ maxHeight: "200px", width: "auto" }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3 justify-content-center d-flex flex-row">
                                                    <label type="button" className="btn btn btn-info col-md-5" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Upload Multiple Images<input hidden onChange={fileChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" multiple="multiple" /></label>
                                                    <label hidden style={{ textAlign: "center" }} type="button" onClick={() => CHelper.downloadImage(imageSrc)} className="btn btn btn-primary col-md-5"><i className="fa fa-download"></i> Download Image</label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>


                                    <Col md={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="newsAndBlogDetails">News Details</Label>
                                            <Editor
                                                editorState={overviewState}
                                                onEditorStateChange={handleOverviewChange}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label>Tags</Label>
                                            <AvField
                                                name="newsAndBlogTags"
                                                placeholder="Tags"
                                                defaultValue={updateData.newsAndBlogTags}
                                                // onChange={(e)=>{amountChangeEvent(e.target.value);}}
                                                type="text"
                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('tags')}`}
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Col style={{ textAlign: 'right' }}>
                                    <Button color="primary" type="submit">
                                        Submit
                                    </Button>
                                </Col>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            }
        </Row>
    )
}

export default Model
