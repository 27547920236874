import React, { useState, useEffect, useRef } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label,
    CardImg
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import Axios from "../../../helpers/axios_helper"
import * as CHelper from "../../../helpers/custom_helper"
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../../assets/images/media.jpeg";
const moment = require('moment');

const Model = (props) => {
    const history = useHistory()
    const [updateData, setUpdateData] = useState(false)
    const [imageSrc, setImageSrc] = useState(mediaImage);
    const [files, setFiles] = useState([]);

    const handleSubmit = async (event, errors, values) => {
        
        window.sLoader();
        if(errors.length>0){
            errors.forEach(element => {
                toast.error(`${CHelper.separateCamelCaseString(element)} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        else if (props.id && props.id>0 && imageSrc==mediaImage){
            toast.error(`Feature image is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        // else if (!props.id && files.length==0){
        //     toast.error(`Feature image is required !`, {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 3000,
        //     });
        // }
        else{
            if(props.id && props.id==updateData.id){
                const jsonData = {
                    notificationSubject: values.notificationSubject,
                    notificationMessage: values.notificationMessage
                }
                
                await Axios.patch(`/notification/id/${updateData.id}`, jsonData)
                .then(async (response) => {
                    if (response.data.status === 200) {
                        
                        const formData = new FormData();
                        for (let i = 0; i < files.length; i++) {
                            formData.append('file', files[i]);
                        }
                        if(files.length>0){
                            const removeFilesName = [];
                            for (let i = 0; i < updateData.files.length; i++) {
                                removeFilesName.push(updateData.files[i].name);
                            }
                            formData.append(`remove`, JSON.stringify(removeFilesName));
                        }
                        await Axios.post(`/file/upload/notification&${updateData.id}`, formData, {
                        })
                        .then(response => { 
                            toast.success('Notification updated successfully!', {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                            props.handleCallback(response.data)
                        })
                        .catch(function (error) {
                            toast.error("File Uploaded Failed !", {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        });
                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                })
                .catch((e) => {
                    toast.error(e, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                })
            }
            else{
                const jsonData = {
                    notificationSubject: values.notificationSubject,
                    notificationMessage: values.notificationMessage
                }
                
                await Axios.post("/notification", jsonData)
                .then(async (response) => {
                    if (response.data.status === 201) {
                        
                        const formData = new FormData();
                        for (let i = 0; i < files.length; i++) {
                            formData.append('file', files[i]);
                        }
    
                        await Axios.post(`/file/upload/notification&${response.data.id}`, formData, {
                        })
                        .then(res => { 
                            toast.success('Notification created successfully!', {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                            setTimeout(() => {
                                history.push("/notification-list");
                            }, 1000);
                        })
                        .catch(function (error) {
                            toast.error("File Uploaded Failed !", {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        });
                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                })
                .catch((e) => {
                    toast.error(e, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                })
            }
            
        }

        window.hLoader();
    }

    const fileChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setFiles([...event.target.files]);
        CHelper.convertToWebP(documentFile, setImageSrc);
    }


    useEffect(async () => {
        window.sLoader();
        if (props.id && props.id > 0) {
            await Axios.get(`/notification/id/${props.id}`)
            .then((response) => { 
            if(response.data.status===200){
                setUpdateData(response.data.data);
                if(response.data.data.files.length>0){
                    setImageSrc(CHelper.getsimplifyLink(response.data.data.files[0].link))
                }
            }
            })
        }
        window.hLoader();
    }, [props.id]);


    return (
        <Row>
            {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
                <Col xl="12">
                    <Card>
                        <CardBody>
                            <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="6">
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label>Name</Label>
                                                <AvField
                                                    name="notificationSubject"
                                                    // placeholder="Subject"
                                                    defaultValue={updateData.notificationSubject}
                                                    // onChange={(e)=>{amountChangeEvent(e.target.value);}}
                                                    type="text"
                                                    errorMessage={`Please provide ${CHelper.separateCamelCaseString('notificationSubject')}`}
                                                    validate={{ required: { value: true } }}
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label htmlFor="notificationMessage">Message</Label>
                                                <AvField
                                                    name="notificationMessage"
                                                    id="notificationMessage"
                                                    defaultValue={updateData.notificationMessage}
                                                    type="textarea"
                                                    className="form-control"
                                                    errorMessage={`Please provide ${CHelper.separateCamelCaseString('notificationMessage')}`}
                                                    validate={{ required: { value: true } }}
                                                />
                                            </div>
                                        </Col>

                                    </Col>
                                    <Col md="6">
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3" style={{ textAlign: "center" }}>
                                                    {/* <CardTitle className="h4">Feature Image</CardTitle> */}
                                                    <CardImg className="img-fluid" src={imageSrc} alt="" style={{ maxHeight: "300px", width: "auto" }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3 justify-content-center d-flex flex-row">
                                                    <label type="button" className="btn btn btn-info col-md-5" style={{ textAlign: "center" }}><i className="fa fa-upload"></i> Change Image<input hidden onChange={fileChangeEvent} accept="image/png, image/gif, image/jpeg, image/jpg" type="file" multiple="multiple" /></label>
                                                    <label hidden style={{ textAlign: "center" }} type="button" onClick={() => CHelper.downloadImage(imageSrc)} className="btn btn btn-primary col-md-5"><i className="fa fa-download"></i> Download Image</label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Col style={{ textAlign: 'right' }}>
                                    <Button color="primary" type="submit">
                                        Submit
                                    </Button>
                                </Col>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            }
        </Row>
    )
}

export default Model
