import Axios from "./axios_helper"
import * as SHelper from "./session_helper"
import Config from '../config'

export const baseUrl = () => {
  const baseUrl = process.env.REACT_APP_ENV=="production" ? Config.applicationSettings.productionBaseURL : Config.applicationSettings.developmentBaseURL;
  return baseUrl;
}

export const jsonToFormData = (json,formData=new FormData()) => {
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        formData.append(key, json[key]);
      }
    }
    return formData;
}

export const downloadFile = (url, fileName) => {
  let link = document.createElement('a');
  link.href = url;
  link.download = fileName;

  // Check if the file is an image
  if (url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') || url.endsWith('.gif')) {
    // If it's an image, open it in a new window
    window.open(url, '_blank');
  } else {
    // If it's not an image, download it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export const getTextFromUrl = (url) => {
  return fetch(url)
    .then(response => response.text());
}

export const downloadImage=(imageSrc)=> {
  if(!imageSrc)return false;
  const blob = dataURItoBlob(imageSrc);
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  var nameArray = this.expense.expense.documentpath.split('/');
  link.download = nameArray[nameArray.length-1];
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const dataURItoBlob=(dataURI)=> {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

export const convertToWebP = (selectedFile,setImageSrc) => {
  const canvas = document.createElement("canvas");
  const img = document.createElement("img");
  img.src = URL.createObjectURL(selectedFile);
  img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      canvas.getContext("2d").drawImage(img, 0, 0);
      const webpDataUrl = canvas.toDataURL("image/webp");
      if(setImageSrc)setImageSrc(webpDataUrl);
  };
};

export const separateCamelCaseString = (camelCaseString ) => {
  camelCaseString = camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2');
  return camelCaseString.charAt(0).toUpperCase() + camelCaseString.slice(1);
};

export const getsimplifyLink = (link) =>{
  link = link.replace(/\\/g, '/');
  link = link.replace(':/','://');
  return link;
}

export const getBase64FromImageLink = async (link) =>{
  link = getsimplifyLink(link);
  return Axios.get(link, { responseType: 'arraybuffer' })
  .then(response => {
    const base64Image = btoa(
      new Uint8Array(response.data)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    return 'data:image/png;base64,'+base64Image;
  })
  .catch(error => {
    console.log(error);
  });
}

export const getInLocalDatTime = async (dateTime) =>{
  const options = { timeZone: 'Asia/Dhaka', dateStyle: 'medium', timeStyle: 'short' };
  return new Date(dateTime).toLocaleString('en-BD', options);
}
