import React, { useState, useEffect, useRef } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Label,
  CardImg,
  Table,
  Modal
} from "reactstrap"
import { Delete,Edit, VideoCall, Download } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import Moment from 'react-moment';
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import Axios from "../../helpers/axios_helper"
import * as SHelper from "../../helpers/session_helper"
import * as CHelper from "../../helpers/custom_helper"
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';

const Model = (props) => {
  const history = useHistory()
  const [courseClasses, setCourseClasses] = useState([]);
  const [updateData, setUpdateData] = useState(false)
  const [zoomAccessTokenModel, setZoomAccessTokenModel] = useState(false);

  const [courseClassId, setCourseClassId] = useState(-1);
  const [courseClassIdx, setCourseClassIdx] = useState(-1);
  const [courseClassName, setCourseClassName] = useState('');
  const [courseStartDatetime, setCourseStartDatetime] = useState(null);
  const [courseEndDatetime, setCourseEndDatetime] = useState(null);
  const [courseClassDescription, setCourseClassDescription] = useState('');
  
  const [zoomDetailsModalOpen, setZoomDetailsModalOpen] = useState(false)
  const [zoomJoiningLink, setZoomJoiningLink] = useState(false)
  const showMeetingRoom = (data) =>{
    data = JSON.parse(data);
    setZoomJoiningLink(data.start_url);
    setZoomDetailsModalOpen(true)
  }

  const handleSubmit = async (event, errors, values) => {
    if (!props.courseId) {
        toast.error(`Course is required !`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
    }
    else {
      var updateData=courseClasses;
      updateData.forEach(element => {
        element.courseId=props.courseId;
        if(element.courseClassIsActive){
          element.courseClassIsActive=element.courseClassIsActive==1?true:false;
        }
      });

      const jsonData = {
        courseClasses:updateData
      }

      await Axios.post("/courseClass/bulk", jsonData)
        .then(async (response) => {
          if (response.data.status === 200) {
            getClassList();
            toast.success('Course Classes Created/Updated successfully!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        })
        .catch((e) => {
          toast.error(e, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        })

    }

  }

  const addCourseClass = () =>{
    if(courseClassIdx>-1){
        courseClasses[courseClassIdx].id=courseClassId;
        courseClasses[courseClassIdx].courseClassName=courseClassName;
        courseClasses[courseClassIdx].courseClassDescription=courseClassDescription;
        courseClasses[courseClassIdx].courseStartDatetime=courseStartDatetime;
        courseClasses[courseClassIdx].courseEndDatetime=courseEndDatetime;
    }
    else{
      courseClasses.push({
            courseClassName:courseClassName,
            courseClassDescription:courseClassDescription,
            courseStartDatetime:courseStartDatetime,
            courseEndDatetime:courseEndDatetime
        });
    }
    setCourseClasses(courseClasses);
    cancelCourseClassEdit();
  }

  const cancelCourseClassEdit = () =>{
    setCourseClassIdx(-1);
    setCourseClassId(-1);
    setCourseClassName('');
    setCourseClassDescription('');
    setCourseStartDatetime(null);
    setCourseEndDatetime(null);
  }

  const editCourseClass = (id,idx) =>{
    setCourseClassId(id);
    setCourseClassIdx(idx);
    setCourseClassName(courseClasses[idx].courseClassName);
    setCourseStartDatetime(courseClasses[idx].courseStartDatetime);
    setCourseEndDatetime(courseClasses[idx].courseEndDatetime);
    setCourseClassDescription(courseClasses[idx].courseClassDescription);
  }
  const deleteCourseClass = (id,idx) =>{

  }

  const zoomOauthUrl=async ()=>{
    await Axios.get(`/zoom/oauthUrl`)
      .then((response) => {
          if (response.data.url) {
            window.open(response.data.url, '_blank');
          }
      });
  }

  const recheckAccessTokenValidity=async ()=>{
    await Axios.get(`/zoom/getOauthAccessToken`)
      .then((response) => {
          if (response.data.status==200) {
            setZoomAccessTokenModel(false);
            toast.success('Zoom Connected successfully !', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
          else{
            toast.error('Failed to connect with zoom !', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
      });
  }

  const checkAccessTokenValidity=async ()=>{
    await Axios.get(`/zoom/checkAccessTokenValidity`)
      .then((response) => {
          if (response.data.status==200) {
            toast.success('Zoom Connected successfully !', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
          else {
            setZoomAccessTokenModel(true)
            toast.error('Failed to connect with zoom !', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
      });
  }

  const getClassList = async () =>{
    window.sLoader();
    setCourseClasses([])
    await Axios.get(`/courseClass/listByCourse/${props.courseId}`)
          .then((response) => {
              if (response.data.recordsTotal) {
                setCourseClasses(response.data.data);
              }
              else {
                setCourseClasses([])
              }
          });
    window.hLoader();
  }
  useEffect(async ()=>{
    if(props.courseId){
      if(SHelper.userHasEditPermission()){
        checkAccessTokenValidity();
      }  
      getClassList();
    }
  },[props.courseId])

  const startMeeting = async (data) =>{
    if(!data)return false;
    data= JSON.parse(data);
    window.open(data.start_url, '_blank');
  }
  
  
  const joinMeeting = async (data) =>{
    if(!data)return false;
    data= JSON.parse(data);
    let user = SHelper.getUser();
    const values={
      roomNumber:data.id,//"83730438367",
      roomPass:data.password,//"ZL2cu4",
      sdkKey:data.sdkKey,//'iFtSrW6QjqLRQnhgEglQ',
      role:0,
      userName:user.full_name,
      userEmail:(user.role_code=='111' || user.role_code=='222') ? data.host_email:'',
      registrantToken:"",
      zakToken:"",
      leaveUrl:window.location.href,
    };
    let encryptedUrl= SHelper.encrypt(JSON.stringify(values));
    history.push(`/join-meeting?key=${encodeURIComponent(encryptedUrl)}`)
  }

  return (
    <Row>
      {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
        <Col xl="12">
          
          <Card>
            <CardTitle style={{ fontSize: "16px", textAlign: "center", textDecoration: "underline" }}>Classes</CardTitle>
            <CardBody style={{ paddingTop: "0.5rem" }}>
              <AvForm className="needs-validation" onSubmit={handleSubmit}>
              {SHelper.userHasEditPermission() &&
              <>
                <Col md="12">
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label>Name</Label>
                        <AvField
                          name="courseClassName"
                          value={courseClassName}
                          onChange={(e)=>{setCourseClassName(e.target.value);}}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label>Start datetime</Label>
                        <Flatpickr
                            name="instructorDateOfBirth"
                            id="instructorDateOfBirth"
                            className="form-control d-block"
                            // placeholder="dd M, yyyy"
                            value = {courseStartDatetime}
                            options={{
                              dateFormat: 'Y-m-d h:i K',
                              timeFormat: 'h:i K',
                              enableTime: true,
                              time_24hr: false,
                              // altInput: true,
                              // altFormat: 'F j, Y h:i K',
                              // altInputClass: 'flatpickr-alt-input',
                            }}
                            onChange={(selectedDates, dateStr, instance) => {
                                setCourseStartDatetime(dateStr);
                            }}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label>End Datetime</Label>
                        <Flatpickr
                            name="instructorDateOfBirth"
                            id="instructorDateOfBirth"
                            className="form-control d-block"
                            value = {courseEndDatetime}
                            // placeholder="dd M, yyyy"
                            options={{
                              dateFormat: 'Y-m-d h:i K',
                              timeFormat: 'h:i K',
                              enableTime: true,
                              time_24hr: false,
                              // altInput: true,
                              // allowInput: true,
                              // altFormat: 'F j, Y h:i K',
                              // altInputClass: 'flatpickr-alt-input',
                              // parseDate: (datestr, format) => {
                              //   return moment(datestr, format, true).toDate();
                              // },
                              // formatDate: (date, format, locale) => {
                              //   // locale can also be used
                              //   return moment(date).format(format);
                              // }
                            }}
                            onChange={(selectedDates, dateStr, instance) => {
                                setCourseEndDatetime(dateStr);
                            }}
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="mb-3">
                        <Label>Description</Label>
                        <AvField
                          name="courseTypeDescription"
                          value={courseClassDescription}
                          onChange={(e)=>{setCourseClassDescription(e.target.value);}}
                          type="textarea"
                          className="form-control"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <div className="actions clearfix">
                    <Button
                        style={{ float: "right", marginLeft: "10px" }}
                        type="button"
                        color="primary"
                        onClick={addCourseClass}
                    >{ courseClassIdx>-1 ? "Update":"Add"}</Button>
                    { courseClassIdx >-1 && 
                    <Button
                        style={{ float: "right" }}
                        type="button"
                        color="danger"
                        onClick={cancelCourseClassEdit}
                    >Cancel</Button>}
                </div>
                </>}
                <CardTitle typeof="h4" >Classes List</CardTitle>
                  <Col md={12}>
                      <div className="table-responsive">
                          <Table className="table mb-0">
                              <thead>
                                  <tr>
                                      <th>#</th>
                                      <th>Name</th>
                                      <th>Start Datetime</th>
                                      <th>End Datetime</th>
                                      <th>Description</th>
                                      <th>Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                              {courseClasses.map((item,i)=>{
                                      return (
                                          <tr key={i}>
                                              <th scope="row">{i+1}</th>
                                              <td>{item.courseClassName}</td>
                                              <td><Moment date={item.courseStartDatetime} format="YYYY-MM-DD h:mm A"/></td>
                                              <td><Moment date={item.courseEndDatetime} format="YYYY-MM-DD h:mm A"/></td>
                                              <td>{item.courseClassDescription.length>20?`${item.courseClassDescription.substring(0, 20)} ...`:item.courseClassDescription}</td>
                                              <td>
                                                  <Box sx={{ display: courseClassIdx===i?'none':'flex' }}>
                                                      {SHelper.userHasEditPermission() && 
                                                      <Tooltip arrow placement="left" title="Edit">
                                                      <IconButton onClick={() => {editCourseClass(item.id,i)}}>
                                                          <Edit />
                                                      </IconButton>
                                                      </Tooltip>}

                                                      {/* {item.courseZoomDetails && <Tooltip arrow placement="left" title="Start Meeting in Modal">
                                                      <IconButton style={{color:"blue"}} onClick={() => {showMeetingRoom(item.courseZoomDetails)}}>
                                                          <VideoCall />
                                                      </IconButton>
                                                      </Tooltip>} */}
                                                      
                                                      {item.courseZoomDetails && SHelper.userHasEditPermission() && <Tooltip arrow placement="left" title="Start Meeting">
                                                      <IconButton style={{color:"blue"}} onClick={() => {startMeeting(item.courseZoomDetails)}}>
                                                          <VideoCall />
                                                      </IconButton>
                                                      </Tooltip>}

                                                      {item.courseZoomDetails && !SHelper.userHasEditPermission() && <Tooltip arrow placement="left" title="Join Meeting">
                                                      <IconButton style={{color:"blue"}} onClick={() => {joinMeeting(item.courseZoomDetails)}}>
                                                          <VideoCall />
                                                      </IconButton>
                                                      </Tooltip>}

                                                      {SHelper.userHasEditPermission() && 
                                                      <Tooltip arrow placement="left" title="Delete">
                                                      <IconButton style={{color:"red"}} onClick={() => {deleteCourseClass(item.id,i)}}>
                                                          <Delete />
                                                      </IconButton>
                                                      </Tooltip>}

                                                      {(new Date()> new Date(item.courseEndDatetime)) &&
                                                        <Tooltip arrow placement="left" title="Download">
                                                        <IconButton style={{color:"brown"}} onClick={() => {alert("Paid Version Needed")}}>
                                                            <Download />
                                                        </IconButton>
                                                        </Tooltip>
                                                      }
                                                  </Box>
                                              </td>
                                          </tr>
                                      )
                                  })}
                              </tbody>
                          </Table>
                      </div>
                  </Col>
                  {courseClasses.length>0 &&(SHelper.isUserRole(SHelper.userRole.Administrator) || SHelper.isUserRole(SHelper.userRole.Instructor) ) &&
                  <div className="actions clearfix">
                    <Button
                        style={{ float: "right", marginLeft: "10px" }}
                        type="submit"
                        color="primary"
                    >Update</Button>
                </div>}
              </AvForm>
            </CardBody>
          </Card>

          <Modal
            size="sm"
            isOpen={zoomAccessTokenModel}
            centered={true}
            closeButton={false}
            backdrop="static"
            keyboard={false}>
              
            <div className="modal-header">
              <h5 className="modal-title mt-0">No Zoom Account Found !</h5>
              <button
                type="button"
                onClick={() => {
                  setZoomAccessTokenModel(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{padding:"0"}}>
                <CardBody>
                    <Row>
                        <Col md="12">
                            No zoom account permission found. Please allow permission to go forward.
                        </Col>
                    </Row>
                </CardBody>
            </div>
            <div className="modal-footer customBtnArea" style={{textAlign: 'right'}}>
                <Button color="primary" type="button" className="btn btn-sm" onClick={()=>zoomOauthUrl()}>
                    Yes
                </Button>
                <Button color="danger" type="button" className="btn btn-sm" onClick={()=>recheckAccessTokenValidity()}>
                    Re-Check
                </Button>
            </div>
          </Modal>



          <Modal
            size="xl"
            isOpen={zoomDetailsModalOpen}
            centered={true}
            closeButton={false}
            backdrop="static"
            keyboard={false}>
            <div className="modal-body" style={{padding:"0"}}>
                <CardBody>
                    <Row>
                        <Col md="12">
                            <iframe 
                              title="Zoom Meeting"
                              allow="microphone; camera" 
                              src={zoomJoiningLink} 
                              style={{width:"100%", height:`${window.innerHeight-150}px`}}
                              >
                            </iframe>
                        </Col>
                    </Row>
                </CardBody>
            </div>
            <div className="modal-footer customBtnArea" style={{textAlign: 'right'}}>
                <Button color="primary" type="button" className="btn btn-sm" onClick={()=>setZoomDetailsModalOpen(false)}>
                    Close
                </Button>
            </div>
          </Modal>

        </Col>
      }
    </Row>
  )
}

export default Model
