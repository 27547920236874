import React, { useState, useEffect, useRef } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Label,
    CardImg,
    Table,
} from "reactstrap";
import { Delete, Edit } from '@mui/icons-material';
import {
    Box,
    IconButton,
    Tooltip,
} from '@mui/material';
import { AvForm, AvField } from "availity-reactstrap-validation"
import * as CHelper from "../../../helpers/custom_helper"
import Axios from "../../../helpers/axios_helper"
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../../assets/images/media.jpeg";

const Model = (props) => {
    const history = useHistory()
    const [updateData, setUpdateData] = useState(false)
    const [file, setFile] = useState([]);
    const [wcus, setWcus] = useState([]);
    const [imageSrc, setImageSrc] = useState(mediaImage);

    const [wcuIdx, setWcuIdx] = useState(-1);
    const [wcuIcon, setWcuIcon] = useState('');
    const [wcuTitle, setWcuTitle] = useState('');
    const [wcuSequence, setWcuSequence] = useState('');
    const [wcuShortNote, setWcuShortNote] = useState('');
    const [wcuDescription, setWcuDescription] = useState('');

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        var updatedWcus =[];
        wcus.forEach((element, i) => {
            updatedWcus.push({
                wcuIcon : element.wcuIcon,
                wcuTitle : element.wcuTitle,
                wcuShortNote : element.wcuShortNote,
                wcuSequence : element.wcuSequence,
                wcuDescription : element.wcuDescription
            })
        });
        
        const jsonData = {
            homePageWhyChooseUs: JSON.stringify(updatedWcus)
        }


        await Axios.post("/page/homePageWhyChooseUs", jsonData)
            .then(async (response) => {
                if (response.data.status === 200) {

                    toast.success('Why Choose US Created/Updated successfully!', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });

                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                }
            })
            .catch((e) => {
                toast.error(e, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            })
        window.hLoader();
    }
    const addfaq = () => {
        if (wcuIcon.length == 0) {
            toast.error(`Icon is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else if (!wcuTitle || wcuTitle.length == 0) {
            toast.error(`Title is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else if (!wcuShortNote || wcuShortNote.length == 0) {
            toast.error(`Short note is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else if (!wcuSequence>0) {
            toast.error(`Sequence is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {

            if (wcuIdx > -1) {
                wcus[wcuIdx].wcuTitle = wcuTitle;
                wcus[wcuIdx].wcuIcon = wcuIcon;
                wcus[wcuIdx].wcuShortNote = wcuShortNote;
                wcus[wcuIdx].wcuSequence = wcuSequence;
                wcus[wcuIdx].wcuDescription = wcuDescription;
            }
            else {
                wcus.push({
                    wcuTitle : wcuTitle,
                    wcuIcon : wcuIcon,
                    wcuSequence : wcuSequence,
                    wcuShortNote : wcuShortNote,
                    wcuDescription : wcuDescription
                });
            }
            setWcus(wcus);
            cancelfaqEdit();
        }
    }

    const cancelfaqEdit = () => {
        setWcuIdx(-1);
        setWcuIcon('');
        setWcuTitle('');
        setWcuShortNote('');
        setWcuSequence('');
        setWcuDescription('');
    }

    const editWcu = (id, idx) => {
        setWcuIdx(idx);
        setWcuIcon(wcus[idx].wcuIcon);
        setWcuTitle(wcus[idx].wcuTitle);
        setWcuShortNote(wcus[idx].wcuShortNote);
        setWcuSequence(wcus[idx].wcuSequence);
        setWcuDescription(wcus[idx].wcuDescription);
    }

    const deleteWcu = (id, idx) => {
        var filterFaq= wcus.filter(x=>x.idx!=idx);
        setWcus(filterFaq)
    }

    useEffect(async () => {
        window.sLoader();
        await Axios.get(`/page/homePageWhyChooseUs`)
            .then((response) => {
                if (response.data.status == 200 && response.data.data && response.data.data.homePageWhyChooseUs && response.data.data.homePageWhyChooseUs.length>0) {
                    var jsonData = JSON.parse(response.data.data.homePageWhyChooseUs)
                    setWcus(jsonData);
                }
            });
        window.hLoader();
    }, [])

    return (
        <div className="page-content">
            <Breadcrumbs title="Page" breadcrumbItem="Why Choose US" />
            <Row>
                {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
                    <Col xl="12">
                        <Card>
                            <CardBody style={{ paddingTop: "0.5rem" }}>
                                <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                    <Col md="12">
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label>Icon</Label>
                                                    <AvField
                                                        name="wcuicon"
                                                        value={wcuIcon}
                                                        onChange={(e) => { setWcuIcon(e.target.value); }}
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label>Title</Label>
                                                    <AvField
                                                        name="wcuTitle"
                                                        value={wcuTitle}
                                                        onChange={(e) => { setWcuTitle(e.target.value); }}
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label>Short Note</Label>
                                                    <AvField
                                                        name="wcuShortNote"
                                                        value={wcuShortNote}
                                                        onChange={(e) => { setWcuShortNote(e.target.value); }}
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label>Sequence</Label>
                                                    <AvField
                                                        name="wcuSequence"
                                                        value={wcuSequence}
                                                        onChange={(e) => { setWcuSequence(e.target.value); }}
                                                        type="number"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3">
                                                    <Label>Description</Label>
                                                    <AvField
                                                        name="wcuDescription"
                                                        value={wcuDescription}
                                                        onChange={(e) => { setWcuDescription(e.target.value); }}
                                                        type="textarea"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <div className="actions clearfix">
                                        <Button
                                            style={{ float: "right", marginLeft: "10px" }}
                                            type="button"
                                            color="primary"
                                            onClick={addfaq}
                                        >{wcuIdx > -1 ? "Update" : "Add"}</Button>
                                        {wcuIdx > -1 &&
                                            <Button
                                                style={{ float: "right" }}
                                                type="button"
                                                color="danger"
                                                onClick={cancelfaqEdit}
                                            >Cancel</Button>}
                                    </div>
                                    <CardTitle typeof="h4" >Why Choose US List</CardTitle>
                                    <Col md={12}>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Title</th>
                                                        <th>Icon</th>
                                                        <th>Sequence</th>
                                                        <th>Short Note</th>
                                                        <th>Description</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {wcus.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <th scope="row">{i + 1}</th>
                                                                <td>{item.wcuTitle}</td>
                                                                <td>{item.wcuIcon}</td>
                                                                <td>{item.wcuSequence}</td>
                                                                <td>{item.wcuShortNote && item.wcuShortNote.length > 20 ? `${item.wcuShortNote.substring(0, 20)} ...` : item.wcuShortNote}</td>
                                                                <td>{item.wcuDescription && item.wcuDescription.length > 20 ? `${item.wcuDescription.substring(0, 20)} ...` : item.wcuDescription}</td>
                                                                <td>
                                                                    <Box sx={{ display: wcuIdx === i ? 'none' : 'flex' }}>
                                                                        <Tooltip arrow placement="left" title="Edit">
                                                                            <IconButton onClick={() => { editWcu(item.id, i) }}>
                                                                                <Edit />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip arrow placement="left" title="Delete">
                                                                            <IconButton style={{ color: "red" }} onClick={() => { deleteWcu(item.id, i) }}>
                                                                                <Delete />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Box>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                    {wcus.length > 0 &&
                                        <div className="actions clearfix">
                                            <Button
                                                style={{ float: "right", marginLeft: "10px" }}
                                                type="submit"
                                                color="primary"
                                            >Update</Button>
                                        </div>}
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                }
            </Row>
        </div>
    )
}

export default Model
