import React, { useState, useEffect, useRef } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Label,
  CardImg,
  Table
} from "reactstrap"
import { Delete,Edit } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import Axios from "../../helpers/axios_helper";
import * as SHelper from "../../helpers/session_helper"
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/media.jpeg";
const moment = require('moment');

const Model = (props) => {
  const [instructors, setInstructors] = useState([]);
  const [selectedInstructors, setSelectedInstructors] = useState([]);
  const [courseInstructors, setCourseInstructors] = useState([]);

  const handleSubmit = async (event, errors, values) => {
    
    var updateData=[];
    selectedInstructors.forEach(element => {
      updateData.push({
        courseId:props.courseId,
        instructorProfileId:element.value
      })
    });

    const jsonData = {
      courseInstructors:updateData
    }

    await Axios.post("/courseInstructor/bulk", jsonData)
      .then(async (response) => {
        if (response.data.status === 200) {
          fetchData();
          toast.success('Course Classes Created/Updated successfully!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
              
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      })
      .catch((e) => {
        toast.error(e, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      })

  }

  const deleteCourseClass = (id,idx) =>{

  }

  const fetchData = async () =>{
      await Axios.get(`/courseInstructor/list/courseId/${props.courseId}`)
        .then((response) => {
            if (response.data.status == 200) {
              setCourseInstructors(response.data.data);
              let filteredInstructors =[]
              response.data.data.forEach(element => {
                filteredInstructors.push({label:element.instructorFullName,value:element.id})
              });
              setSelectedInstructors(filteredInstructors)
            }
            else {
              setCourseInstructors([])
            }
      });
  }

  useEffect(async ()=>{
    if(props.courseId){
        fetchData();
        if(SHelper.userHasEditPermission()){
          await Axios.get(`/instructorProfile/dropdown`)
          .then((response) => {
              if (response.data.status == 200) {
                setInstructors(response.data.data);
              }
              else {
                setInstructors([])
              }
          });
        }
    }
  },[props.courseId])

  return (
    <Row>
        <Col xl="12">
          <Card>
            <CardTitle style={{ fontSize: "16px", textAlign: "center", textDecoration: "underline" }}>Instructors</CardTitle>
            <CardBody style={{ paddingTop: "0.5rem" }}>
              <AvForm className="needs-validation" onSubmit={handleSubmit}>
              {SHelper.userHasEditPermission() && <>
                <Col md="12">
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label>Name</Label>
                        <Select
                          value={selectedInstructors}
                          isMulti={true}
                          onChange={(data) => {
                            setSelectedInstructors(data);
                          }}
                          options={instructors}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    
                  </Row>
                </Col>
                <div className="actions clearfix">
                    <Button
                        style={{ float: "right", marginLeft: "10px" }}
                        type="button"
                        color="primary"
                        onClick={handleSubmit}
                    >Update</Button>
                </div>
                </>}
                <CardTitle typeof="h4" >Instructors List</CardTitle>
                  <Col md={12}>
                      <div className="table-responsive">
                          <Table className="table mb-0">
                              <thead>
                                  <tr>
                                      <th>#</th>
                                      <th>Name</th>
                                      <th>Contact</th>
                                      <th>Email</th>
                                      {SHelper.userHasEditPermission() &&
                                      <th>Action</th>}
                                  </tr>
                              </thead>
                              <tbody>
                              {courseInstructors.map((item,i)=>{
                                    return (
                                        <tr key={i}>
                                            <th scope="row">{i+1}</th>
                                            <td>{item.instructorFullName}</td>
                                            <td>{item.instructorContactNo}</td>
                                            <td>{item.instructorEmail}</td>
                                            {SHelper.userHasEditPermission() && 
                                            <td>
                                                <Box sx="flex">
                                                    <Tooltip arrow placement="left" title="Delete">
                                                    <IconButton style={{color:"red"}} onClick={() => {deleteCourseClass(item.id)}}>
                                                        <Delete />
                                                    </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </td>}
                                        </tr>
                                      )
                                  })}
                              </tbody>
                          </Table>
                      </div>
                  </Col>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
    </Row>
  )
}

export default Model
