import PropTypes from "prop-types"
import React, { useEffect, useRef, useCallback, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }
    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement
      if (parent2) {
        parent2.classList.add("mm-show") // ul tag
        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }, []);
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, []);

  const scrollElement = (item) => {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }



  return (
    <>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            <li>
              <Link to="/dashboard" className=" waves-effect">
                <i className="mdi mdi-airplay"></i>
                {/* <span className="badge rounded-pill bg-info float-end">new</span> */}
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>


            <li className="menu-title">{props.t("Administrator")}</li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-file-tree"></i>
                <span>{props.t("Administrator")}</span>
              </Link>
              <ul className="sub-menu">
              
              <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-airplay"></i>
                    <span>{props.t("Homepage")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/home-page-slider">{props.t("Slider")}</Link>
                    </li>
                    <li>
                      <Link to="/home-page-why-choose-us">{props.t("Why Choose US")} </Link>
                    </li>
                    <li>
                      <Link to="/about-organization">{props.t("About Org.")} </Link>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <Link to="/about-organization" className=" waves-effect">
                    <i className="mdi mdi-airplay"></i>
                    <span>{props.t("About Org.")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-clipboard-account"></i>
                    <span>{props.t("Page")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/page-create">{props.t("New Page Create")}</Link>
                    </li>
                    <li>
                      <Link to="/page-list">{props.t("Pages List")} </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-clipboard-account"></i>
                    <span>{props.t("Web Message")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/web-message-list">{props.t("Web Messages List")} </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-clipboard-account"></i>
                    <span>{props.t("Notice")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/notification-create">{props.t("New Notice Create")}</Link>
                    </li>
                    <li>
                      <Link to="/notification-list">{props.t("Notice List")} </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-clipboard-account"></i>
                    <span>{props.t("Course Type")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/course-type-create">{props.t("Create Course Type")}</Link>
                    </li>
                    <li>
                      <Link to="/course-type-list">{props.t("Course Types List")} </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-clipboard-account"></i>
                    <span>{props.t("Event")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/event-type-create">{props.t("Create Event Type")}</Link>
                    </li>
                    <li>
                      <Link to="/event-type-list">{props.t("Event Types List")} </Link>
                    </li>
                    <li>
                      <Link to="/event-create">{props.t("Create Event")}</Link>
                    </li>
                    <li>
                      <Link to="/event-list">{props.t("Events List")} </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-clipboard-account"></i>
                    <span>{props.t("News")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/news-create">{props.t("Create News")}</Link>
                    </li>
                    <li>
                      <Link to="/news-list">{props.t("News List")} </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-clipboard-account"></i>
                    <span>{props.t("Blogs")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/blog-create">{props.t("Create Blog")}</Link>
                    </li>
                    <li>
                      <Link to="/blog-list">{props.t("Blogs List")} </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-clipboard-account"></i>
                    <span>{props.t("Footer Pages")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/contact-us">{props.t("Contact US")}</Link>
                    </li>
                    <li>
                      <Link to="/faq">{props.t("FAQ")} </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">{props.t("Privacy Policy")} </Link>
                    </li>
                    <li>
                      <Link to="/terms-and-condition">{props.t("Terms & Condition")} </Link>
                    </li>
                  </ul>
                </li>

              </ul>
            </li>

            <li className="menu-title">{props.t("Participant")}</li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-clipboard-account"></i>
                <span>{props.t("Participant")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/academic-profile-list">{props.t("Participants List")} </Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">{props.t("Instructor")}</li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-clipboard-account"></i>
                <span>{props.t("Instructor")}</span>
              </Link>
              <ul className="sub-menu">
              <li>
                  <Link to="/instructor-profile-create">{props.t("Create Instructor")} </Link>
                </li>
                <li>
                  <Link to="/instructor-profile-list">{props.t("Instructors List")} </Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">{props.t("Course")}</li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-clipboard-account"></i>
                <span>{props.t("Course")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/course-create">{props.t("Create Course")}</Link>
                </li>
                <li>
                  <Link to="/course-list">{props.t("Courses List")} </Link>
                </li>
              </ul>
            </li>
            {/* <li>
              <Link to="/course?page=syllabus" className=" waves-effect">
                <i className="mdi mdi-clipboard-account"></i>
                <span>{props.t("Course curriculum")}</span>
              </Link>
            </li> */}

            {/* <li className="menu-title">{props.t("Meeting")}</li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-clipboard-account"></i>
                <span>{props.t("Zoom")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/join-meeting-1">{props.t("Join Meeting")} </Link>
                  <Link to="/join-meeting-2">{props.t("Redirect")} </Link>
                  <Link to="/join-meeting">{props.t("Web View")} </Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))