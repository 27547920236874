import React, { useState, useEffect } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Axios from "../../../helpers/axios_helper"
import * as CHelper from "../../../helpers/custom_helper"
import { toast } from 'react-toastify/dist/react-toastify';

const Model = (props) => {
    const [footerPageDetails, setFooterPageDetails] = useState(false);
    const [contactNo, setContactNo] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [contactDetails, setContactDetails] = useState('')
    const [fbLink, setFbLink] = useState('')
    const [insLink, setInsLink] = useState('')
    const [linkLink, setLinkLink] = useState('')
    const [twiLink, setTwiLink] = useState('')
    const [socialDetails, setSocialDetails] = useState('')

    const handleSubmit = async (event, errors, values) => {
        if (errors.length > 0) {
            errors.forEach(element => {
                toast.error(`${CHelper.separateCamelCaseString(element)} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        else {
            const data = {
                contactNo: contactNo,
                email: email,
                address: address,
                contactDetails: contactDetails,
                fbLink: fbLink,
                twiLink: twiLink,
                insLink: insLink,
                linkLink: linkLink,
                socialDetails: socialDetails
            }

            const jsonData = {
                footerPageDetails: JSON.stringify(data)
            }

            await Axios.post("/footerPage/contactUS", jsonData)
                .then(async (response) => {
                    if (response.data.status === 200) {
                        toast.success('Contact US updated successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                })
                .catch((e) => {
                    toast.error(e, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                })

        }

    }


    useEffect(async () => {
        await Axios.get(`/footerPage/contactUS`)
            .then((response) => {
                if (response.data.status === 200) {
                    setFooterPageDetails(response.data.data);
                    var details = JSON.parse(response.data.data.footerPageDetails);
                    setContactNo(details.contactNo)
                    setEmail(details.email)
                    setAddress(details.address)
                    setContactDetails(details.contactDetails)
                    setFbLink(details.fbLink)
                    setInsLink(details.insLink)
                    setLinkLink(details.linkLink)
                    setTwiLink(details.twiLink)
                    setSocialDetails(details.socialDetails)
                }
            })
    }, []);


    return (
        <div className="page-content">
            <Breadcrumbs title="Page" breadcrumbItem="Contact US" />
            <Row>
                {(!props.id || props.id === 0 || (props.id > 0 && footerPageDetails)) &&
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>Contact No</Label>
                                                <AvField
                                                    name="contactNo"
                                                    value={contactNo}
                                                    onChange={(e) => { setContactNo(e.target.value) }}
                                                    errorMessage={`Please provide ${CHelper.separateCamelCaseString('contactNo')}`}
                                                    required
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>Email</Label>
                                                <AvField
                                                    name="email"
                                                    value={email}
                                                    onChange={(e) => { setEmail(e.target.value) }}
                                                    errorMessage={`Please provide ${CHelper.separateCamelCaseString('email')}`}
                                                    validate={{ required: { value: true } }}
                                                    type="email"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label>Address</Label>
                                                <AvField
                                                    name="address"
                                                    value={address}
                                                    onChange={(e) => { setAddress(e.target.value) }}
                                                    errorMessage={`Please provide ${CHelper.separateCamelCaseString('address')}`}
                                                    validate={{ required: { value: true } }}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label>Contact Details</Label>
                                                <AvField
                                                    name="contactDetails"
                                                    value={contactDetails}
                                                    onChange={(e) => { setContactDetails(e.target.value) }}
                                                    type="textarea"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label htmlFor="socialDetails">Social Media Details</Label>
                                                <AvField
                                                    name="socialDetails"
                                                    value={socialDetails}
                                                    onChange={(e) => { setSocialDetails(e.target.value) }}
                                                    type="textarea"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label htmlFor="fbLink">Facebook Link</Label>
                                                <AvField
                                                    name="fbLink"
                                                    value={fbLink}
                                                    onChange={(e) => { setFbLink(e.target.value) }}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label htmlFor="linkLink">LinkedIn Link</Label>
                                                <AvField
                                                    name="linkLink"
                                                    value={linkLink}
                                                    onChange={(e) => { setLinkLink(e.target.value) }}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label htmlFor="insLink">Instragram Link</Label>
                                                <AvField
                                                    name="insLink"
                                                    value={insLink}
                                                    onChange={(e) => { setInsLink(e.target.value) }}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label htmlFor="twiLink">Twitter Link</Label>
                                                <AvField
                                                    name="twiLink"
                                                    value={twiLink}
                                                    onChange={(e) => { setTwiLink(e.target.value) }}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Col style={{ textAlign: 'right' }}>
                                        <Button color="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                }
            </Row>
        </div>

    )
}

export default Model
