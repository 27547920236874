import React, { useState, useEffect, useRef } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label,
    CardImg
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Axios from "../../../helpers/axios_helper"
import * as CHelper from "../../../helpers/custom_helper"
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';

const Model = (props) => {
    const history = useHistory()
    const [userDetails, setUserDetails] = useState(false);
    const [password, setPassword] = useState(false);
    const [cPassword, setCPassword] = useState(false);

    const handleSubmit = async (event, errors, values) => {

        window.sLoader();
        if (errors.length > 0) {
            errors.forEach(element => {
                toast.error(`${CHelper.separateCamelCaseString(element)} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        else {
            const jsonData = {
                username: values.username,
                password: values.password
            }

            await Axios.patch(`/user/id/${props.userDetailsAll.id}`, jsonData)
                .then(async (response) => {
                    if (response.data.status === 200) {
                        setPassword("");
                        setCPassword("");
                        toast.success('User updated successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                })
                .catch((e) => {
                    toast.error(e, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                })

        }

        window.hLoader();
    }


    useEffect(async () => {
        if (props.userDetailsAll) {
            setUserDetails(props.userDetailsAll)
        }
    }, [props.userDetailsAll]);


    return (
        <Row>
            {userDetails &&
                <Col xl="12">
                    <Card>
                        <CardBody style={{ paddingTop: "0" }}>
                            <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label>Username</Label>
                                            <AvField
                                                name="username"
                                                defaultValue={userDetails.username}
                                                // onChange={(e)=>{amountChangeEvent(e.target.value);}}
                                                type="text"
                                                errorMessage={`Please provide ${CHelper.separateCamelCaseString('username')}`}
                                                validate={{ required: { value: true } }}
                                                className="form-control"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label>New Password</Label>
                                            <AvField
                                                name="password"
                                                // onChange={(e)=>{amountChangeEvent(e.target.value);}}
                                                type="text"
                                                value={password}
                                                className="form-control"
                                                onChange={(e)=>setPassword(e.target.value)}
                                                // errorMessage={`Please provide ${CHelper.separateCamelCaseString('password')}`}
                                                // validate={{ async: (value, ctx, input, cb) => { return (( ctx.cpassword == ctx.password)) } }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label>Confirm Password</Label>
                                            <AvField
                                                name="cpassword"
                                                // onChange={(e)=>{amountChangeEvent(e.target.value);}}
                                                type="text"
                                                className="form-control"
                                                value={cPassword}
                                                onChange={(e)=>setCPassword(e.target.value)}
                                                errorMessage={`Password and Confirm must be same !`}
                                                validate={{ async: (value, ctx, input, cb) => { return (( ctx.cpassword == ctx.password)) } }}
                                            />
                                        </div>
                                    </Col>

                                </Row>
                                <Col style={{ textAlign: 'right' }}>
                                    <Button color="primary" type="submit">
                                        Update
                                    </Button>
                                </Col>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            }
        </Row>
    )
}

export default Model
