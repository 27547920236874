import React, { useState, useEffect, useRef, Fragment } from 'react';
import * as SHelper from '../../helpers/session_helper';
import { useLocation, useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Alert, Container } from "reactstrap"
import { toast } from 'react-toastify/dist/react-toastify';
import queryString from 'query-string';
import KJUR from 'jsrsasign'

function Redirect() {
  const location = useLocation();
  const history = useHistory();
  const queryParams = queryString.parse(location.search);

  const [error, setError] = useState(false);

  const roomNumber = useRef('');
  const roomPass = useRef('');
  const authEndpoint = useRef('');
  const sdkKey = useRef('iFtSrW6QjqLRQnhgEglQ');
  const sdkSecret = useRef('gu9HAuZWladqK7KEWAASU5Mbf9qcKwbr');
  const role = useRef(0);
  const userName = useRef('Asiqur Rahman');
  const userEmail = useRef('');
  const registrantToken = useRef('');
  const zakToken = useRef('');
  const leaveUrl = useRef(`${window.location.protocol}//${window.location.hostname}:${window.location.port}`);

  async function getSignature() {

    const iat = Math.round(new Date().getTime() / 1000) - 30;
    const exp = iat + 60 * 60 * 2

    const oHeader = { alg: 'HS256', typ: 'JWT' }

    const oPayload = {
      sdkKey: sdkKey.current,
      mn: roomNumber.current,
      role: role.current,
      iat: iat,
      exp: exp,
      appKey: sdkKey.current,
      tokenExp: iat + 60 * 60 * 2
    }

    const sHeader = JSON.stringify(oHeader)
    const sPayload = JSON.stringify(oPayload)
    const signature = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, sdkSecret.current)
    return signature;
  }

  useEffect(async () => {

    if (queryParams.key) {
      try {
        const key = queryParams.key;
        let decryptedKey = SHelper.decrypt(key);
        let jsonData = JSON.parse(decryptedKey);
        roomNumber.current = jsonData.roomNumber
        roomPass.current = jsonData.roomPass
        authEndpoint.current = jsonData.authEndpoint
        // sdkKey.current = jsonData.sdkKey
        role.current = jsonData.role
        userName.current = jsonData.userName
        userEmail.current = jsonData.userEmail
        registrantToken.current = jsonData.registrantToken
        zakToken.current = jsonData.zakToken
        leaveUrl.current = jsonData.leaveUrl

        const { ZoomMtg } = await import("@zoomus/websdk");

        ZoomMtg.setZoomJSLib('https://source.zoom.us/2.11.5/lib', '/av');
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareWebSDK();
        // loads language files, also passes any error messages to the ui
        ZoomMtg.i18n.load('en-US');
        ZoomMtg.i18n.reload('en-US');

        ZoomMtg.generateSDKSignature({
          meetingNumber: roomNumber.current,
          role: role.current,
          sdkKey: sdkKey.current,
          sdkSecret: sdkSecret.current,
          success: function (signature) {

            ZoomMtg.init({
              leaveUrl: leaveUrl.current,
              success: (success) => {
                console.log(success)

                ZoomMtg.join({
                  signature: signature.result,
                  sdkKey: sdkKey.current,
                  meetingNumber: roomNumber.current,
                  passWord: roomPass.current,
                  userName: userName.current,
                  userEmail: userEmail.current,
                  tk: registrantToken.current,
                  zak: zakToken.current,
                  success: (success) => {
                    console.log(success)
                  },
                  error: (error) => {
                    console.log(error)
                  }
                })

              },
              error: (error) => {
                console.log(error)
              }
            })
          }
        })

      } catch (error) {
        setError(true)
        toast.error('Key is not valid !', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
    else {
      setError(true)
      toast.error('No key found !', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [queryParams])

  return (
    <><Fragment>
      <link type="text/css" rel="stylesheet" href="https://source.zoom.us/2.11.5/css/bootstrap.css" />
      <link type="text/css" rel="stylesheet" href="https://source.zoom.us/2.11.5/css/react-select.css" />
    </Fragment>
      {error &&
        <div className="account-pages pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={6} lg={5} xl={4}>
                <div className="card overflow-hidden">
                  <div className="card-body pt-1">
                    <div className="p-2">
                        <div className="mt-3">
                          <Link
                            to="/"
                            className="btn btn-primary w-100 waves-effect waves-light"
                          >
                            Get Back to Dasboard
                          </Link>
                        </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

          </Container>
        </div>
      }
    </>);
}

export default Redirect;
