import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Input,
    Card,
    Button as RButton,
    CardBody,
    CardTitle,
    Label,
    CardImg,
    Table,
} from "reactstrap";
import { Delete, Edit, ChangeCircle, Download } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material';
import { AvForm, AvField } from "availity-reactstrap-validation"
import * as CHelper from "../../helpers/custom_helper"
import Axios from "../../helpers/axios_helper"
import * as SHelper from "../../helpers/session_helper"
import { useHistory } from "react-router-dom"
import { toast,Slide } from 'react-toastify/dist/react-toastify';
import CustomModal from "../Common/CustomModal"

const Model = (props) => {
    const [file, setFile] = useState([]);
    const [files, setFiles] = useState([]);
    const [fileOriginalName, setFileOriginalName] = useState('');

    const selectedItem = useRef(0)
    const selectedItemId = useRef(0)
    const [update_IsActive_modal_center, setUpdate_IsActive_modal_center] = useState(false);
    const [delete_modal_center, setDelete_modal_center] = useState(false);

    const [fileId, setFileId] = useState(-1);
    const [fileIdx, setFileIdx] = useState(-1);
    const [fileName, setFileName] = useState('');
    const [fileSequence, setFileSequence] = useState('');

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        const selectedProperties = files.map(({id, fileName, fileSequence, fileNameWithExt}) => ({id, fileName, fileSequence, fileNameWithExt}));

        const formData = new FormData();

        files.forEach(element => {
          formData.append('file', element.file);
        });

        formData.append('body', JSON.stringify(selectedProperties));

        await Axios.post(`/file/uploadWithDB/course&${props.courseId}`, formData, {
        })
        .then(response => {
            toast.success('File Created/Updated successfully!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            fetchData();
        })
        .catch(function (error) {
            toast.error("File Uploaded Failed !", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        });

        window.hLoader();
    }

    const addFile = () => {
        if (fileName.length == 0) {
            toast.error(`Name is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        if (!file) {
            toast.error(`File is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else if (fileSequence == 0) {
            toast.error(`Sequence is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {
            const customFile = new File([file], `${fileName}.${file.name.split(".").pop()}`, { type: file.type });

            if (fileIdx > -1) {
                files[fileIdx].id = fileId;
                files[fileIdx].idx = files.length;
                files[fileIdx].fileName = customFile.name;
                files[fileIdx].fileSequence = fileSequence;
                files[fileIdx].fileNameWithExt = customFile.name;
                files[fileIdx].file = customFile;
            }
            else {
                files.push({
                    id : fileId,
                    idx : files.length,
                    fileName : fileName,
                    fileSequence : fileSequence,
                    fileNameWithExt : customFile.name,
                    file : customFile
                });
            }
            setFiles(files);
            cancelFileEdit();
        }
    }

    const cancelFileEdit = () => {
        setFileId(-1);
        setFileIdx(-1);
        setFileName('');
        setFileSequence('');
        setFileOriginalName('');
        setFile(null);
        try {
          window.document.getElementById("inputGroupFile02").value = "";
        } catch (error) {}
        
    }

    const editFile = (id, idx) => {
        setFileIdx(idx);
        setFileName(files[idx].fileName);
        setFileSequence(files[idx].fileSequence);
        setFileOriginalName(files[idx].fileOriginalName);
        setFile(files[idx].file);
    }

    const deleteFile = async (id) => {
        if(id && id>0){
          let filterFile = files.filter(x=>x.id==id)
          if(filterFile.length>0){
            filterFile=filterFile[0];
          }
          const url = `/file/id/${id}`;
          await Axios.post(url,{folderName: filterFile.fileFolderName, folderId: filterFile.fileFolderId, fileNameWithExt:filterFile.fileNameWithExt})
            .then(function (response) {
              if (response && response.data?.status==200) {
                document.getElementById(`fileIsActive${id}`).innerHTML=response.data.fileIsActive==1?"True":"False"
                toast.success(response.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                  transition: Slide,
                });
                fetchData();
              }
              else {
                toast.error(response.statusText ?? "Data fetching failed !", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                  transition: Slide,
                });
              }
            })
            .catch(function (error) {
              // navigate('/');
            });
        }

        var filterFaq= files.filter(x=>x.idx!=selectedItemId.current);
        setFiles(filterFaq)
    }

    const fileChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setFile(documentFile);
        setFileOriginalName(documentFile.name)
    }

    const updateIsActiveStatus = async (id) =>{
      const url = `/file/changeFileStatus/${id}`;
      await Axios.patch(url)
        .then(function (response) {
          if (response && response.data?.status==200) {
            document.getElementById(`fileIsActive${id}`).innerHTML=response.data.fileIsActive==1?"True":"False"
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              transition: Slide,
            });
          }
          else {
            toast.error(response.statusText ?? "Data fetching failed !", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              transition: Slide,
            });
          }
        })
        .catch(function (error) {
          // navigate('/');
        });
    }

    const modalCallbackDelete = async (result) =>{
      if(result){
        deleteFile(selectedItem.current);
      }
      setUpdate_IsActive_modal_center(false);
      selectedItem.current=0;
      selectedItemId.current=0;
    }
    
    const modalCallbackIsActiveVisibility = async (result) =>{
      if(result){
        updateIsActiveStatus(selectedItem.current);
      }
      setUpdate_IsActive_modal_center(false);
      selectedItem.current=0;
    }

    const fetchData=async()=>{
      window.sLoader();
        await Axios.get(`/file/uploadWithDB/course&${props.courseId}`)
            .then((response) => {
                if (response.data.status == 200 && response.data.data) {
                    setFiles(response.data.data);
                }
            });
        window.hLoader();
    }

    useEffect(async () => {
      if(props.courseId){
        await fetchData();
      }
    }, [props.courseId])

    return (
        <Row>
          <Col xl="12">
              <Card>
                <CardTitle style={{ fontSize: "16px",color:"red", paddingTop:"10px" ,textAlign: "center", textDecoration: "underline" }}>Paid Version Needed</CardTitle>
              </Card>
          </Col>
        </Row>
    )
}

export default Model
