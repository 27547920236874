import React, { useState, useEffect, useRef } from "react"
import Instructor from "../../InstructorProfile/model"

const Model = (props) => {  

    const [instructorProfileId, setInstructorProfileId] =useState(false);

    useEffect(()=>{
        if(props.userDetailsAll.userDetail){
            setInstructorProfileId(props.userDetailsAll.userDetail.instructorProfileId)
        }
    },[props.userDetailsAll])

    return (
        <>{instructorProfileId!=false && <Instructor id={instructorProfileId} userDetailId={props.userDetailsAll.userDetail.id} noPadding={true}/>}</>
    )
}

export default Model
