import React, { useMemo, useEffect, useState, useRef } from 'react';
import { Row, Col, Card, CardBody, Modal } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TableLoader from "../../../components/Common/TableLoader"
import Axios from "../../../helpers/axios_helper"

import { toast,Slide } from 'react-toastify/dist/react-toastify';
import MaterialReactTable from 'material-react-table';
import { Edit,ChangeCircle,Delete } from '@mui/icons-material';
import CustomModal from "../../Common/CustomModal"
import PageModel from "./model"
import {
  Box,
  Button,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material';

const DatatableTables = () => {

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [columnFilters, setColumnFilters] = useState([]);
  const [columnFilterFns, setColumnFilterFns] = useState({
    pageName: 'contains',
    pageTitle: 'contains',
    pageBreadcrumb: 'contains',
    'menuHead.menuName': 'contains'
  });

  const selectedItem = useRef(0)
  const [modal_center, setmodal_center] = useState(false);
  const [update_status_modal_center, setUpdate_status_modal_center] = useState(false);
  const [delete_modal_center, setDelete_modal_center] = useState(false);

  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const tableInstanceRef = useRef(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5
  });


  const columns = useMemo(
    () => [
      {
        size:50,
        accessorKey: 'sl',
        header: "#SL",
        Cell: ({ cell, row }) => (
          // <Typography variant='h7'>{row.original['menuHead.mname']}</Typography>
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: false,
        enableColumnActions:false,
        enableSorting: false
      },
      {
        size:50,
        accessorKey: 'menuHead.menuName',
        header: "Menu head",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{row.original['menuHead.menuName']}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        size:50,
        accessorKey: 'pageName',
        header: "Name",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'pageTitle',
        header: "Title",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'pageBreadcrumb',
        header: "Breadcrumb",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        size:50,
        accessorKey: 'pageIsActive',
        header: "Is Active",
        Cell: ({ cell, row }) => (
          <Col style={{minWidth:"100px"}}>
          <Typography variant='h7' id={`pageIsActive${row.original.id}`}>{cell.getValue()==1?"True":"False"}</Typography>
          <Tooltip arrow placement="left" title="Change">
            <IconButton onClick={() => {
              selectedItem.current=row.original.id; setUpdate_status_modal_center(true)
              }}
              >
              <ChangeCircle />
            </IconButton>
          </Tooltip>
          </Col>
        ),
        enableColumnFilterModes: false,
        enableColumnActions:false,
        enableSorting: false
      }
      // {
      //   accessorKey: 'gender',
      //   header: "Invoice #",
      //   Filter: ({ column, header, table }) => (
      //     <LocalizationProvider dateAdapter={AdapterDayjs}>
      //       <input
      //         type="date"
      //         style={{ margin: '3px 0 2px 0', border: 'none', color: '#777' }}
      //         onChange={(newValue) => {
      //           column.setFilterValue(newValue.target.value);
      //         }}
      //         value={column.getFilterValue()}
      //       />
      //       <hr style={{ margin: '0px', borderColor: 'black' }} />
      //       <p style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}>Filter Mode: contains</p>
      //     </LocalizationProvider>

      //   ),
      //   enableColumnFilterModes: true,
      //   filterFn: 'contains',
      //   columnFilterModeOptions: ['contains'],
      // }
    ],
    [],
  );

  const findFilterCondition = (name) => {
    var condition = '';
    if (columnFilterFns[name]) {
      condition = columnFilterFns[name];
    }
    return condition;
  }

  const fetchDataFromAPI = async (reset = false) => {
    // window.sLoader();
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    let filterQuery = '';
    let sorterQuery = '';

    if (!reset) {
      if (columnFilters.length > 0) {
        columnFilters.forEach(element => {
          if (element.id === 'invoicedate' || element.id === 'duedate') {
            // filterQuery += `&filter=${element.id},${findFilterCondition(element.id)},${AuthVerify.getDate(element.value)}`;
          }
          else {
            filterQuery += `&filter=${element.id},${findFilterCondition(element.id)},${element.value}`;
          }
        });
      }
      if (sorting.length > 0) {
        sorting.forEach(element => {
          sorterQuery += `&sortby=${element.id}&orderby=${element.desc ? 'desc' : 'asc'}`;
        });
      }
    } else {
      setPagination({
        pageIndex: 0,
        pageSize: 5
      });
      tableInstanceRef.current.resetRowSelection();
    }

    //Get Invoices
    //api/account/invoice
    let url = `/page/list?limit=${pagination.pageSize}&offset=${pagination.pageIndex * pagination.pageSize}${sorterQuery}${filterQuery}`;
    // let url = `/page/list`;
    await Axios.get(url)
      .then(function (response) {
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
        if (response && response.data?.data) {
          setData(response.data?.data);
          setRowCount(response.data.recordsTotal);
        }
        else {
          toast.error(response.statusText ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
      // window.hLoader();
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting
  ]);

  const updateActiveStatus = async (id) =>{
    const url = `/page/changeStatus/${id}`;
    await Axios.patch(url)
      .then(function (response) {
        if (response && response.data?.status==200) {
          document.getElementById(`pageIsActive${id}`).innerHTML=response.data.pageIsActive==1?"True":"False"
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            transition: Slide,
          });
        }
        else {
          toast.error(response.statusText ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
  }

  const deletePage = () => {
    const url = `/page/id/${selectedItem.current}`;
    Axios.delete(url)
      .then(function (response) {
        if (response && response.data?.status==200) {
          fetchDataFromAPI(true);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            transition: Slide,
          });
        }
    });
  }

  const deleteModalCallbackStatus = async (result) =>{
    if(result){
      deletePage(selectedItem.current);
    }
    setDelete_modal_center(false);
    selectedItem.current=0;
  }

  const modalCallbackStatus = async (result) =>{
    if(result){
      updateActiveStatus(selectedItem.current);
    }
    setUpdate_status_modal_center(false);
    selectedItem.current=0;
  }

  const handleCallback = (response) =>{
    selectedItem.current=0;
    setmodal_center(false);
    fetchDataFromAPI();
  }
                       
  return (
    <>
      <div className="page-content">

        <Breadcrumbs title="Page" breadcrumbItem="List of Pages" />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {true ?
                  <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableColumnActions={false}
                    enableGlobalFilter={false}
                    enableColumnFilterModes
                    initialState={{
                      showColumnFilters: false
                    }}
                    manualFiltering
                    manualPagination
                    manualSorting
                    onColumnFilterFnsChange={setColumnFilterFns}
                    onColumnFiltersChange={setColumnFilters}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    tableInstanceRef={tableInstanceRef}
                    rowCount={rowCount}
                    enableEditing
                    state={{
                      columnFilters,
                      columnFilterFns,
                      globalFilter,
                      isLoading,
                      pagination,
                      showAlertBanner: isError,
                      showProgressBars: isRefetching,
                      sorting,
                    }}
                    positionActionsColumn="last"
                    displayColumnDefOptions={{
                      'mrt-row-actions': {
                        header: 'Action',
                      },
                    }}

                    renderRowActions={({ row, table }) => (
                      <Box sx={{ display: 'flex' }}>
                        <Tooltip arrow placement="left" title="Edit">
                          <IconButton onClick={() => {selectedItem.current=row.original.id;  setmodal_center(true)}}>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="left" title="Delete">
                          <IconButton style={{ color: "red" }} onClick={() => {selectedItem.current=row.original.id; setDelete_modal_center(true) }}>
                              <Delete />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  />
                  :
                  <TableLoader />
                }
              </CardBody>
            </Card>
          </Col>

          <Modal
            size="lg"
            isOpen={modal_center}
            centered={true}>

            <div className="modal-header">
              <h5 className="modal-title mt-0">Update Page</h5>
              <button
                type="button"
                onClick={handleCallback}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{padding:"0"}}>
              <PageModel id={selectedItem.current} handleCallback={handleCallback}/>
            </div>
          </Modal>

          <CustomModal modelShow={delete_modal_center} handleCallback={deleteModalCallbackStatus}/>
          <CustomModal modelShow={update_status_modal_center} handleCallback={modalCallbackStatus}/>
        </Row>
      </div>

    </>
  )
}

export default DatatableTables
