import React, { useState, useEffect, useRef } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Label,
    CardImg,
    Table,
} from "reactstrap";
import { Delete, Edit } from '@mui/icons-material';
import {
    Box,
    IconButton,
    Tooltip,
} from '@mui/material';
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import Axios from "../../../helpers/axios_helper"
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';

const Model = (props) => {
    const history = useHistory()
    const [faqs, setfaqs] = useState([]);
    const [updateData, setUpdateData] = useState(false)
    const [files, setFiles] = useState([]);

    const [courseModuleId, setCourseModuleId] = useState(0);
    const [modules, setModules] = useState([]);

    const [faqId, setFaqId] = useState(-1);
    const [faqIdx, setFaqIdx] = useState(-1);
    const [faqQuestion, setFaqQuestion] = useState('');
    const [faqAnswer, setFaqAnswer] = useState('');

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        faqs.forEach((element, i) => {
            element.idx = i;
        });
        const jsonData = {
            footerPageDetails: JSON.stringify(faqs)
        }

        await Axios.post("/footerPage/faq", jsonData)
            .then(async (response) => {
                if (response.data.status === 200) {
                    toast.success('Course Modules Created/Updated successfully!', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });

                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                }
            })
            .catch((e) => {
                toast.error(e, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            })
        window.hLoader();

    }
    const addfaq = () => {
        if (faqQuestion.length == 0) {
            toast.error(`Question is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else if (faqAnswer.length == 0) {
            toast.error(`Answer is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {

            if (faqIdx > -1) {
                faqs[faqIdx].id = faqId;
                faqs[faqIdx].faqQuestion = faqQuestion;
                faqs[faqIdx].faqAnswer = faqAnswer;
            }
            else {
                faqs.push({
                    faqQuestion: faqQuestion,
                    faqAnswer: faqAnswer
                });
            }
            setfaqs(faqs);
            cancelfaqEdit();
        }
    }

    const cancelfaqEdit = () => {
        setFaqId(-1);
        setFaqIdx(-1);
        setFaqQuestion('');
        setFaqAnswer('');
    }

    const editfaq = (id, idx) => {
        setFaqId(id);
        setFaqIdx(idx);
        setFaqQuestion(faqs[idx].faqQuestion);
        setFaqAnswer(faqs[idx].faqAnswer);
    }

    const deletefaq = (id, idx) => {
        var filterFaq= faqs.filter(x=>x.idx!=idx);
        setfaqs(filterFaq)
    }

    useEffect(async () => {
        await Axios.get(`/footerPage/faq`)
            .then((response) => {
                if (response.data.status == 200) {
                    var details = JSON.parse(response.data.data.footerPageDetails)
                    setfaqs(details);
                }
                else {
                    setfaqs([])
                }
            });
    }, [])

    return (
        <div className="page-content">
            <Breadcrumbs title="Page" breadcrumbItem="FAQ" />
            <Row>
                {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
                    <Col xl="12">
                        <Card>
                            <CardBody style={{ paddingTop: "0.5rem" }}>
                                <AvForm className="needs-validation" onSubmit={handleSubmit}>
                                    <Col md="12">
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                    <Label>Name</Label>
                                                    <AvField
                                                        name="faqName"
                                                        value={faqQuestion}
                                                        onChange={(e) => { setFaqQuestion(e.target.value); }}
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3">
                                                    <Label>Name</Label>
                                                    <AvField
                                                        name="faqName"
                                                        value={faqAnswer}
                                                        onChange={(e) => { setFaqAnswer(e.target.value); }}
                                                        type="textarea"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <div className="actions clearfix">
                                        <Button
                                            style={{ float: "right", marginLeft: "10px" }}
                                            type="button"
                                            color="primary"
                                            onClick={addfaq}
                                        >{faqIdx > -1 ? "Update" : "Add"}</Button>
                                        {faqIdx > -1 &&
                                            <Button
                                                style={{ float: "right" }}
                                                type="button"
                                                color="danger"
                                                onClick={cancelfaqEdit}
                                            >Cancel</Button>}
                                    </div>
                                    <CardTitle typeof="h4" >FAQ's List</CardTitle>
                                    <Col md={12}>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Question</th>
                                                        <th>Answer</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {faqs.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <th scope="row">{i + 1}</th>
                                                                <td>{item.faqQuestion && item.faqQuestion.length > 20 ? `${item.faqQuestion.substring(0, 20)} ...` : item.faqQuestion}</td>
                                                                <td>{item.faqAnswer && item.faqAnswer.length > 20 ? `${item.faqAnswer.substring(0, 20)} ...` : item.faqAnswer}</td>
                                                                <td>
                                                                    <Box sx={{ display: faqIdx === i ? 'none' : 'flex' }}>
                                                                        <Tooltip arrow placement="left" title="Edit">
                                                                            <IconButton onClick={() => { editfaq(item.id, i) }}>
                                                                                <Edit />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip arrow placement="left" title="Delete">
                                                                            <IconButton style={{ color: "red" }} onClick={() => { deletefaq(item.id, i) }}>
                                                                                <Delete />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Box>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                    {faqs.length > 0 &&
                                        <div className="actions clearfix">
                                            <Button
                                                style={{ float: "right", marginLeft: "10px" }}
                                                type="submit"
                                                color="primary"
                                            >Update</Button>
                                        </div>}
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                }
            </Row>
        </div>
    )
}

export default Model
